import React, { useState } from 'react';
import { Alert, Row, Col, Accordion } from 'react-bootstrap';
import { ZZZCharacter } from '../components/zzz-character';
import { ZZZStat } from '../components/zzz-stat';
import { ZZZEngine } from '../components/zzz-engine';
import { ZZZDiskSet } from '../components/zzz-disk-set';
import { ZZZCharactersDaze } from '../data/zzz-daze-calcs';

interface IProps {
  slug: string;
  element: string;
}

export const ZZZCharacterCalcsDaze: React.FC<IProps> = ({ slug, element }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dpsRanking, setDpsRanking] = useState(
    ZZZCharactersDaze.find((rankingCharacter) => {
      if (rankingCharacter.slug === slug) return true;
    })
  );

  return (
    <>
      {!dpsRanking ? (
        <></>
      ) : (
        <>
          <>
            <Alert variant="primary alert-red">
              <p>
                <strong>
                  Don't use the damage output calculations as an indication on
                  who is worth pulling or investing
                </strong>{' '}
                as those are just simulations and in day-to-day gameplay there's
                a lot variables and also the skill of the player is important.
                As usual,{' '}
                <strong>don't compare the numbers between characters</strong>.
              </p>
            </Alert>
            {slug === 'lighter' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Lighter's Mindscape 1, 2 and 4 are all potent team-wide
                    supportive bonuses. The calculations below are limited only
                    to each of their effects on Lighter himself. Make sure to
                    consider the impact of these Mindscapes on the entire squad
                    when evaluating his worth and pull value.
                  </li>
                  <li>
                    In addition to the Damage Increase displayed in our
                    calculations, Lighter's Mindscape 6 also increases the
                    frequency in which he can perform his optimal Morale
                    rotation. We can't represent the full benefits this provides
                    in our one rotation format but it will result in a further
                    increase to total Daze, damage and availability over the
                    course of combat.
                  </li>
                </ul>
              </>
            )}
            {slug === 'caesar' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Caesar's Mindscape 1 is balanced around the benefits it
                    provides to the entire team - not her personal damage or
                    Daze output. The impact on Caesar's kit itself is small but
                    this Mindscape is tremendously powerful in the context of
                    the entire teams damage output.
                  </li>
                  <li>
                    Caesar's Mindscape 2 similar to her Mindscape 1 is support
                    focused and boosts her maximum flat Attack boost from 1000
                    to 1500. Again a very powerful team buff which has its value
                    understated when only looking at its impact on Caesar's kit.
                  </li>
                  <li>
                    Caesar's Mindscape 4 is pure Quality of Life and does not
                    increase her or her teams throughput. The additional assist
                    points from this Mindscape will ensure Caesar is able to
                    much more consistently execute many Defensive Assist combos
                    in combat though.
                  </li>
                </ul>
              </>
            )}
            {slug === 'qingyi' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Qingyi's Mindscape 1 offers a myriad of effects - some of
                    which are easily displayed in calculations such as the
                    damage bonuses and others which are harder to display such
                    as her increased access to her "Voltage" Gauge. The initial
                    full "Voltage" gauge offered by this Mindscape is great
                    quality of life and allows Qingyi to start off combat with a
                    good amount of Subjugation stacks and Daze but only works
                    once, meaning we can't accurately include it in
                    calculations. Also the 30% increased accumulation rate of
                    "Voltage" has varying degrees of impact depending on how you
                    sequence Qingyi's abilities. In the rotation we chose for
                    our calculations we already generated close to the limit of
                    "Voltage" (90) before executing her finishing attack Moonlit
                    Blossoms. In attack sequences that only generate the bare
                    minimum "Voltage" to execute Qingyi's finisher (75) you can
                    expect a higher increase from this mindscape to both damage
                    and daze.
                  </li>
                  <li>
                    Qingyi's Mindscape 2 offers a solid increase to her team
                    supportive capabilities which is not represented in her
                    personal Daze or damage calculations. It upgrades her
                    maximum Stun multiplier from 230% (20 stacks of subjugation)
                    to 258% giving her and her team a solid burst damage boost.
                    Also of note is that the additional Daze bonus provided by
                    Mindscape 2 varies in how hard it is to activate. Against
                    normal/elite enemies you can expect to gain the bonus below
                    thanks to Qingyi applying 20 stacks per Moonlit Blossoms
                    finisher. Against bosses though the bonus will have lower
                    uptime and less benefit than is represented as Qingyi will
                    gain maximum stacks much later in her rotation.
                  </li>
                  <li>
                    Qingyi's Mindscape 4 is great quality of life when it
                    triggers granting additional energy and a shield but is not
                    consistent or transformative enough to alter her rotation
                    which is why it doesn't offer any increases below.
                  </li>
                  <li>
                    Qingyi's Mindscape 6 is an absurd team wide damage boost
                    dramatically increasing her own personal damage and
                    supportive capabilities on any team she's present in - it's
                    by far her best Mindscape.
                  </li>
                </ul>
              </>
            )}
            {slug === 'koleda' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Koleda's Mindscape 2 immediately recovers the energy of any
                    EX she uses with a cooldown of 45 seconds. This is
                    incredibly powerful but due to the long cooldown we haven't
                    included the power potential in calculations. That doesn't
                    mean this Mindscape isn't very very strong - you'll likely
                    gain access to this effect 2 or 3 times in an endgame fight,
                    2 or 3 more EX's is nothing at all to look down on!
                  </li>
                </ul>
              </>
            )}
            {slug === 'lycaon' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Lycaon's Mindscape 2 and 4 aren't directly applicable to the
                    Main Field Daze Rotation used for calculations but still
                    hold combat value upon activation. Lycaon's M2 specifically
                    can grant him enough energy over the course of a fight to
                    gain an additional EX Special or just gain access to it
                    sooner making all the difference.
                  </li>
                </ul>
              </>
            )}
            {slug === 'anby-demara' && (
              <>
                <p>
                  <strong>Important!</strong>
                </p>
                <ul>
                  <li>
                    Anby's Mindscape 1 and 4 don't directly impact her Main
                    Field Daze Rotation used for calculations but still hold
                    combat value upon activation. Her M1 can be optionally
                    activated and maintained to boost Energy Generation at the
                    small cost of lowering the Daze of 1 basic combo. Anby's M4
                    can grant other Electric Agents a meaningful amount of
                    energy potentially granting them an additional EX over the
                    course of longer fights.
                  </li>
                </ul>
              </>
            )}
            <Row xs={1} xl={1} className="dps-comparison">
              <Col>
                <div className="box">
                  <h5>
                    {dpsRanking.name} in 1 Target scenario -{' '}
                    <strong className="daze">Daze</strong> and{' '}
                    <strong className="damage">Damage</strong> output in{' '}
                    {slug === 'koleda'
                      ? 'Quick Swap Rotation.'
                      : 'Main Field Rotation.'}
                  </h5>
                  <p className="summary">
                    Rotation time:{' '}
                    <strong className={element}>{dpsRanking.time}s</strong>.
                  </p>
                  <p>
                    <strong className="red">
                      Attention!!! Calculations between characters that use
                      Quick Swap Daze vs Main Field Daze Rotations should not be
                      compared as they are two different playstyles!
                    </strong>
                  </p>
                  <div className={`simulation-container ${slug}`}>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M6</span>
                      </div>
                      <div className="chart">
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar daze`}
                            style={{ width: '100%' }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg_standard.damage_e6.toLocaleString()}{' '}
                              Daze (
                              {dpsRanking.dps_standard.damage_e6.toLocaleString()}{' '}
                              DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps_standard.damage_e6 /
                                  dpsRanking.dps_standard.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar damage`}
                            style={{ width: '100%' }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e6.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e6.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e6 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M5</span>
                      </div>
                      <div className="chart">
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar daze`}
                            style={{
                              width:
                                (dpsRanking.dps_standard.damage_e5 /
                                  dpsRanking.dps_standard.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg_standard.damage_e5.toLocaleString()}{' '}
                              Daze (
                              {dpsRanking.dps_standard.damage_e5.toLocaleString()}{' '}
                              DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps_standard.damage_e5 /
                                  dpsRanking.dps_standard.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar damage`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e5 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e5.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e5.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e5 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M4</span>
                      </div>
                      <div className="chart">
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar daze`}
                            style={{
                              width:
                                (dpsRanking.dps_standard.damage_e4 /
                                  dpsRanking.dps_standard.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg_standard.damage_e4.toLocaleString()}{' '}
                              Daze (
                              {dpsRanking.dps_standard.damage_e4.toLocaleString()}{' '}
                              DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps_standard.damage_e4 /
                                  dpsRanking.dps_standard.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar damage`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e4 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e4.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e4.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e4 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M3</span>
                      </div>
                      <div className="chart">
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar daze`}
                            style={{
                              width:
                                (dpsRanking.dps_standard.damage_e3 /
                                  dpsRanking.dps_standard.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg_standard.damage_e3.toLocaleString()}{' '}
                              Daze (
                              {dpsRanking.dps_standard.damage_e3.toLocaleString()}{' '}
                              DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps_standard.damage_e3 /
                                  dpsRanking.dps_standard.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar damage`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e3 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e3.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e3.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e3 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M2</span>
                      </div>
                      <div className="chart">
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar daze`}
                            style={{
                              width:
                                (dpsRanking.dps_standard.damage_e2 /
                                  dpsRanking.dps_standard.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg_standard.damage_e2.toLocaleString()}{' '}
                              Daze (
                              {dpsRanking.dps_standard.damage_e2.toLocaleString()}{' '}
                              DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps_standard.damage_e2 /
                                  dpsRanking.dps_standard.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar damage`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e2 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e2.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e2.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e2 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M1</span>
                      </div>
                      <div className="chart">
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar daze`}
                            style={{
                              width:
                                (dpsRanking.dps_standard.damage_e1 /
                                  dpsRanking.dps_standard.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg_standard.damage_e1.toLocaleString()}{' '}
                              Daze (
                              {dpsRanking.dps_standard.damage_e1.toLocaleString()}{' '}
                              DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps_standard.damage_e1 /
                                  dpsRanking.dps_standard.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar damage`}
                            style={{
                              width:
                                (dpsRanking.dps.damage_e1 /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage_e1.toLocaleString()} DMG (
                              {dpsRanking.dps.damage_e1.toLocaleString()} DPS)
                            </span>
                            <span className="percent">
                              {(
                                (dpsRanking.dps.damage_e1 /
                                  dpsRanking.dps.damage) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simulation-row special">
                      <div className="character with-eidolon">
                        <ZZZCharacter
                          slug={dpsRanking.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="eidolon">M0</span>
                      </div>
                      <div className="chart">
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar daze`}
                            style={{
                              width:
                                (dpsRanking.dps_standard.damage /
                                  dpsRanking.dps_standard.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg_standard.damage.toLocaleString()}{' '}
                              Daze (
                              {dpsRanking.dps_standard.damage.toLocaleString()}{' '}
                              DPS)
                            </span>
                            <span className="percent">100%</span>
                          </div>
                        </div>
                        <div className="dps-bar-outside">
                          <div
                            className={`dps-bar damage`}
                            style={{
                              width:
                                (dpsRanking.dps.damage /
                                  dpsRanking.dps.damage_e6) *
                                  100 +
                                '%'
                            }}
                          ></div>
                          <div className="data smaller">
                            <span className="dps">
                              {dpsRanking.dmg.damage.toLocaleString()} DMG (
                              {dpsRanking.dps.damage.toLocaleString()} DPS)
                            </span>
                            <span className="percent">100%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <h5>Details about the calculations</h5>
            {dpsRanking.disclaimer != '' && (
              <p>
                <strong>Disclaimer:</strong> {dpsRanking.disclaimer}
              </p>
            )}
            <Accordion className="default-look dps-details">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Rotations</Accordion.Header>
                <Accordion.Body>
                  {slug === 'lighter' && (
                    <>
                      <p>
                        The rotation below assumes Lighter begins with 100
                        Morale but can also be started at 80 Morale in a pinch,
                        but at the cost of losing some supportive and Dazing
                        benefits. Additionally the rotation can also be
                        initiated with any of Lighter's abilities which allow
                        him to skip straight to his 5th Basic Attack.
                      </p>
                      <blockquote>
                        <h6 className="burst">Main Field Extended Rotation</h6>
                        <ul>
                          <li>Defensive Assist: Swift Break</li>
                          <li>Assist Follow-Up: Charging Slam - Stab</li>
                          <li>
                            Basic Attack: L-Form Thundering Fist (5th-Hit
                            Combo)(Morale Boosted)
                          </li>
                          <li>
                            Basic Attack: L-Form Thundering Fist (5th-Hit
                            Powerful Finisher)(Morale Boosted)
                          </li>
                          <li>
                            EX Special Attack: V-Form Sunrise Uppercut - Full
                            Distance{' '}
                          </li>
                          <li>
                            EX Special Attack: V-Form Sunrise Uppercut - Full
                            Distance (Follow-Up){' '}
                          </li>
                          <li>Basic Attack: L-Form Thundering Fist P4 </li>
                          <li>
                            Basic Attack: L-Form Thundering Fist (5th-Hit
                            Startup){' '}
                          </li>
                          <li>
                            Basic Attack: L-Form Thundering Fist (5th-Hit Combo){' '}
                          </li>
                          <li>
                            Basic Attack: L-Form Thundering Fist (5th-Hit
                            Finisher){' '}
                          </li>
                          <li>
                            Dodge Counter: Blazing Flash (Can be executed at any
                            time)
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'caesar' && (
                    <>
                      <p>
                        The rotation below is one of the most common
                        combinations of Caesar's abilities which she'll often
                        repeat multiple times throughout a fight but not the
                        only one. The inclusion of additonal/different combos
                        and perfect blocks will alter the total value of
                        Mindscapes listed. For this reason treat these
                        calculations as a guideline and not the exact
                        performance of the character in all scenarios.
                      </p>
                      <blockquote>
                        <h6 className="burst">Core Rotation</h6>
                        <ul>
                          <li>Defensive Assist: Aegis Shield</li>
                          <li>Assist Follow-Up: Aiding Blade</li>
                          <li>EX Special Attack: Overpowered Shield Bash</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'qingyi' && (
                    <>
                      <p>
                        This rotation is a rough representation of Qingyi's Main
                        Field Daze Rotation for the purpose of calculations. In
                        actual combat you should expect to find yourself
                        sequencing abilities slightly differently based on your
                        access to EX's, Dodge Counters and how attentive you are
                        with the "Voltage" gauge. Opting to continue executing
                        Basic attack Part 3 for longer durations to build
                        additional "Voltage" or using the Non-held EX are also
                        completely viable.
                      </p>
                      <blockquote>
                        <h6 className="burst">Main Field Daze Rotation</h6>
                        <ul>
                          <li>Dodge Counter: Lingering Sentiments </li>
                          <li>
                            Basic Attack: Penultimate P3 (Tap Until Flash){' '}
                          </li>
                          <li>Basic Attack: Penultimate P4 (Enhanced) </li>
                          <li>EX Special Attack: Moonlit Begonia (Hold) </li>
                          <li>
                            Basic Attack: Enchanted Moonlit Blossoms: Rush ATK
                            (All 5 Hits){' '}
                          </li>
                          <li>
                            Basic Attack: Enchanted Moonlit Blossoms: Finishing
                            ATK{' '}
                          </li>
                          <li>Dodge Counter: Lingering Sentiments </li>
                          <li>
                            Basic Attack: Penultimate P3 (Tap Until Flash){' '}
                          </li>
                          <li>Basic Attack: Penultimate P4 (Enhanced) </li>
                          <li>EX Special Attack: Moonlit Begonia (Hold) </li>
                          <li>
                            Basic Attack: Enchanted Moonlit Blossoms: Rush ATK
                            (All 5 Hits){' '}
                          </li>
                          <li>
                            Basic Attack: Enchanted Moonlit Blossoms: Finishing
                            ATK
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'koleda' && (
                    <>
                      <p>
                        This rotation is a rough representation of Koleda's
                        Quick Swap Daze Rotation for the purpose of
                        calculations. In combat you may not always be able to
                        fully execute Koleda's enhanced basic attacks due to
                        their awkward nature. You may also find you achieve more
                        or less Defensive Assists than listed depending on the
                        enemy you are facing at the time increasing or
                        decreasing Koleda's total Daze output.
                      </p>
                      <p>
                        <strong className="red">
                          Attention!!! Calculations between characters that use
                          Quick Swap Daze vs Main Field Daze Rotations should
                          not be compared as they are two different playstyles!
                        </strong>
                      </p>
                      <blockquote>
                        <h6 className="burst">Quick Swap Daze Rotation</h6>
                        <ul>
                          <li>Defensive Assist: Protective Hammer </li>
                          <li>Assist Follow-Up: Hammer Bell </li>
                          <li>EX Special Attack: Boiling Furnace</li>
                          <li>
                            Dash Attack: Tremble! (Can be auto cast using basic
                            right after EX){' '}
                          </li>
                          <li>Basic Attack: Smash 'n' Bash P2 </li>
                          <li>Enhanced Basic Attack P1 </li>
                          <li>Enhanced Basic Attack P2 </li>
                          <li>
                            Special Attack: Hammer Time (Automatically Cast){' '}
                          </li>
                          <li>Defensive Assist: Protective Hammer </li>
                          <li>Assist Follow-Up: Hammer Bell </li>
                          <li>Defensive Assist: Protective Hammer </li>
                          <li>Assist Follow-Up: Hammer Bell </li>
                          <li>EX Special Attack: Boiling Furnace </li>
                          <li>
                            Dash Attack: Tremble! (Can be auto cast using basic
                            right after EX){' '}
                          </li>
                          <li>Basic Attack: Smash 'n' Bash P2 </li>
                          <li>Enhanced Basic Attack P1 </li>
                          <li>Enhanced Basic Attack P2 </li>
                          <li>
                            Special Attack: Hammer Time (Automatically Cast)
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'lycaon' && (
                    <>
                      <p>
                        This rotation is a rough representation of the
                        character's Main Field Daze Rotation for the purpose of
                        calculations. In combat you may need to interrupt your
                        Basic Attack sequences to perform Dodges and may also
                        find you gain more or less Dodge Counters than
                        represented here.
                      </p>
                      <p>
                        <strong className="red">
                          Attention!!! Calculations between characters that use
                          Quick Swap Daze vs Main Field Daze Rotations should
                          not be compared as they are two different playstyles!
                        </strong>
                      </p>
                      <blockquote>
                        <h6 className="burst">Main Field Daze Rotation</h6>
                        <ul>
                          <li>Dodge Counter: Etiquette Manual </li>
                          <li>Basic Attack: Moon Hunter P1 (Charged) </li>
                          <li>Basic Attack: Moon Hunter P2 (Charged) </li>
                          <li>Basic Attack: Moon Hunter P3 (Charged) </li>
                          <li>Basic Attack: Moon Hunter P4 (Charged) </li>
                          <li>Basic Attack: Moon Hunter P5 (Charged Lvl 2) </li>
                          <li>Dodge Counter: Etiquette Manual </li>
                          <li>
                            EX Special Attack: Thirll of the Hunt (Charged){' '}
                          </li>
                          <li>Dodge Counter: Etiquette Manual </li>
                          <li>Basic Attack: Moon Hunter P1 (Charged) </li>
                          <li>Basic Attack: Moon Hunter P2 (Charged) </li>
                          <li>Basic Attack: Moon Hunter P3 (Charged) </li>
                          <li>Basic Attack: Moon Hunter P4 (Charged) </li>
                          <li>Basic Attack: Moon Hunter P5 (Charged Lvl 2) </li>
                          <li>Dodge Counter: Etiquette Manual </li>
                          <li>
                            EX Special Attack: Thirll of the Hunt (Charged)
                          </li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                  {slug === 'anby-demara' && (
                    <>
                      <p>
                        This rotation is a rough representation of the
                        character's Main Field Daze Rotation for the purpose of
                        calculations. In combat you may need to interrupt your
                        Basic Attack sequences to perform Dodges and may also
                        find you gain more or less Dodge Counters than
                        represented here.
                      </p>
                      <p>
                        <strong className="red">
                          Attention!!! Calculations between characters that use
                          Quick Swap Daze vs Main Field Daze Rotations should
                          not be compared as they are two different playstyles!
                        </strong>
                      </p>
                      <blockquote>
                        <h6 className="burst">Main Field Daze Rotation</h6>
                        <ul>
                          <li>Dodge Counter: Thunderclap </li>
                          <li>Basic Attack: Turbo Volt P1 </li>
                          <li>Basic Attack: Turbo Volt P2 </li>
                          <li>Basic Attack: Turbo Volt P3 </li>
                          <li>EX Special Attack: Lightning Bolt</li>
                          <li>Basic Attack: Thunderbolt </li>
                          <li>Dodge Counter: Thunderclap </li>
                          <li>Basic Attack: Turbo Volt P1 </li>
                          <li>Basic Attack: Turbo Volt P2 </li>
                          <li>Basic Attack: Turbo Volt P3 </li>
                          <li>Basic Attack: Thunderbolt </li>
                          <li>Dodge Counter: Thunderclap </li>
                          <li>Basic Attack: Turbo Volt P1 </li>
                          <li>Basic Attack: Turbo Volt P2 </li>
                          <li>Basic Attack: Turbo Volt P3 </li>
                          <li>EX Special Attack: Lightning Bolt</li>
                          <li>Basic Attack: Thunderbolt </li>
                          <li>Dodge Counter: Thunderclap</li>
                        </ul>
                      </blockquote>
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Character build</Accordion.Header>
                <Accordion.Body>
                  <p>
                    And here are the W-Engines and Disk Drives used in the
                    calculations.
                  </p>
                  <div className="build-tips damage">
                    <div className={`single-item without-notes ${element}`}>
                      <ZZZEngine
                        name={dpsRanking.weapon}
                        superImp={dpsRanking.weapon_S}
                        mode="profile"
                      />
                    </div>
                    <div className={`single-item without-notes ${element}`}>
                      <div className="double-set">
                        <ZZZDiskSet
                          name={dpsRanking.set_1}
                          onProfile
                          setup="4"
                        />
                        <ZZZDiskSet
                          name={dpsRanking.set_2}
                          onProfile
                          setup="2"
                        />
                      </div>
                    </div>
                    <div className="main-stats">
                      <div className="box">
                        <div className="stats-inside">
                          <strong className={`${element}`}>Disk 4</strong>
                        </div>
                        <div className="list-stats">
                          <ZZZStat stat={dpsRanking.disk_4} />
                        </div>
                      </div>
                      <div className="box">
                        <div className="stats-inside">
                          <strong className={`${element}`}>Disk 5</strong>
                        </div>
                        <div className="list-stats">
                          <ZZZStat stat={dpsRanking.disk_5} />
                        </div>
                      </div>
                      <div className="box">
                        <div className="stats-inside">
                          <strong className={`${element}`}>Disk 6</strong>
                        </div>
                        <div className="list-stats">
                          <ZZZStat stat={dpsRanking.disk_6} />
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col>
                        <div className="box sub-stats damage">
                          <p>
                            <span>Substats:</span> {dpsRanking.substats}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        </>
      )}
    </>
  );
};
