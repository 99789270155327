/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ZZZCharacter } from '../components/zzz-character';

interface IProps {
  name: string;
  character_element?: string;
}

export const ZZZProfileSynergies: React.FC<IProps> = ({ name }) => {
  return (
    <>
      {name === 'qingyi' && (
        <>
          <p>
            Qingyi is truly flexible and can be played with every Combat
            character in the game thanks to her incredibly easy to satisfy
            Additional Ability condition along with her universal Stun damage
            multiplier. As she’s good with everyone she currently lacks any
            specific must build/pull synergies however, she is strongest in Zhu
            Yuan’s team at the time of her release.
          </p>
        </>
      )}
      {name != 'qingyi' && (
        <div className="character-synergy box">
          {name === 'lighter' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="ellen"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Ice is one of the two elements Lighter specialises in
                    supporting and Ellen is an incredible Ice DPS, so it is of
                    no surprise the two work very well together. The combination
                    of ICE DMG%, ICE RES% Shred, extended Stuns and very low
                    field time requirements gives Ellen everything she ever
                    wanted from a Stunner. With this combo Ellen’s damage is
                    substantially increased and she can freely monopolize the
                    majority of the field time for her lengthy attack combos.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="soldier-11"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Soldier 11 is an accessible and Solid Fire DPS who is more
                    than happy to take up the field time Lighter doesn’t need
                    with her mostly Basic Attack focused gameplay. Lighter
                    offers Soldier 11 all his usual benefits of FIRE DMG%, FIRE
                    RES% SHRED and extended Stun time making the two a solid
                    combo for those not possessing more powerful limited DPS
                    options. Soldier 11 also gains a bit of an additional edge
                    from Lighter compared to others thanks to her Additional
                    Ability functioning specifically against Stunned enemies
                    making Lighter more accessible.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'yanagi' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="burnice"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Burnice and Yanagi make one of the strongest Anomaly duos in
                    the game at the time of Yanagi’s release, creating an
                    incredibly powerful Disorder team composition. As a main
                    field Disorder Specialist Yanagi prefers team mates who can
                    apply DoT based Anomalies quickly and consistently while
                    taking up as little field time as possible. Burnice more
                    than fulfills all of these requirements giving Yanagi all
                    the field time she needs while constantly applying anomaly
                    through her Afterburn alongside strong burst application
                    from her EX-Specials and Ultimates resulting in frequent and
                    huge Disorder triggers.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="rina"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    When playing Yanagi in Electric focused Shock teams Rina
                    makes for a solid choice thanks to her Electric/Shock
                    specific buffs and excellent PEN Ratio bonus which stacks
                    nicely with Yanagi’s “Kagen” Stance PEN Ratio passive. Rina
                    also takes up very little field time allowing Yanagi to
                    combo off as she pleases without restriction. Thanks to
                    Yanagi’s Polarity Disorder - one of the major pain points of
                    pure Shock teams (damage being limited by how many Shock
                    triggers you could achieve) is also diminished.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'burnice' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="yanagi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Burnice and Yanagi make one of the strongest Anomaly duos in
                    the game at the time of Yanagi’s release, creating an
                    incredibly powerful Disorder team composition. As a main
                    field Disorder Specialist Yanagi prefers team mates who can
                    apply DoT based Anomalies quickly and consistently while
                    taking up as little field time as possible. Burnice more
                    than fulfills all of these requirements giving Yanagi all
                    the field time she needs while constantly applying anomaly
                    through her Afterburn alongside strong burst application
                    from her EX-Specials and Ultimates resulting in frequent and
                    huge Disorder triggers.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="jane-doe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Jane is a great Anomaly/Disorder DPS partner for Burnice.
                    She utilizes all available field time Burnice grants the
                    team through her incredibly powerful Basic Attack combos,
                    triggering constant Assaults which synergize excellently
                    with Burnice’s frequent Burns to trigger absolutely
                    monstrous Disorders. Both characters also trigger each
                    other's Additional Ability freeing up the final slot for
                    many different team building options.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="piper"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Alternative primary Anomaly/Disorder DPS teammate for
                    Burnice who can output excellent Physical Anomaly
                    application during her EX-Special attack, triggering some
                    impressive Disorders when aligned correctly. However, she
                    has far more limited options compared to Jane outside of her
                    main EX combo to fill field time, making her somewhat energy
                    hungry but with careful planning she still forms a powerful
                    synergy with the correct third supporting team mate.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="lucy"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Burnice does her best in double DPS teams where she can
                    truly put her off-field damage on display. As such support
                    characters that are able to buff both DPS are more
                    impactful. Lucy is one of such buffers - by being able to
                    apply her Cheer buff to the whole team all at once and
                    maintain it permanently, she grants a significant Flat
                    Attack boost. Furthermore, Lucy requires very little field
                    time of her own granting even more field time to the main
                    DPS of your choice. However, one potential downside of Lucy
                    is that she applies her own Fire Anomaly sometimes,
                    resulting in a slight dilution of Burnice’s own Burns in
                    pure Fire teams or if you’re not able to trigger Disorder
                    fast enough.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'anby-demara' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="grace-howard"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Grace is the backbone of the Shock archetype which is
                    focused on quickly building up as much Electric Anomaly on
                    enemies as you can to keep them Shocked. Anby being an
                    Electric character not only activates both her own and
                    Grace's bonus ability but also contributes her own Anomaly
                    allowing Grace and her team to quickly apply more Shocks,
                    something essential when Anton is a part of the team.
                    Furthermore, Anby also brings Daze to the team, while it
                    isn’t always needed in Shock teams, it's still a viable
                    addition.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="nekomata"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Nekomata has incredibly limited synergizing team mate
                    options when it comes to enabling her Additional Ability,
                    and thankfully Anby can fill that role quite well while also
                    providing Nekomata and her team an excellent amount of Daze.
                    Enabling Nekomata's Additional Ability with Anby allows for
                    the last slot of the team to be used more freely on another
                    DPS or Supporter that complements her better instead of
                    being restricted to selecting specifically a character
                    needed to activate the same.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="billy-kid"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    As a Physical DPS Billy has very little access to Supportive
                    Physical options with the other 3 Physical characters on
                    release all being fellow DPS that take up much of the field
                    time Billy would prefer for himself. Pairing Billy with Anby
                    grants him access to his Additional Ability which provides
                    his entire team solid amount of Daze through parries and
                    EX’s, allowing him to run exactly the Supporters he wants.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'nekomata' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="nicole-demara"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Nicole and Nekomata enable each other’s Additional Ability,
                    opening up the last slot of a team member to your choice.
                    Nicole also provides Nekomata an incredibly impactful
                    Defense Shred debuff on enemies which when timed right can
                    significantly increase Nekomata’s total damage output. On
                    other hand, Nekomata appreciates an ally who can allow her
                    to perform Quick Assist in order to activate her Core
                    Ability and Nicole is not only capable of that but also very
                    good at making it happen as her playstyle revolves around
                    weaving her EX into combat often in order to keep her
                    Defense Shred active. Nicole wants to keep her Defense Shred
                    up using her EX which grants Nekomata a Quick Assist which
                    keeps Nekomata’s buff up ending in a nice synergy giving her
                    the tool’s she needs to deal max damage even when she can’t
                    perform Dodge Counter.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="anby-demara"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Nekomata has incredibly limited synergizing team mate
                    options when it comes to enabling her Additional Ability,
                    and thankfully Anby can fill that role quite well while also
                    providing Nekomata and her team an excellent amount of Daze.
                    Enabling Nekomata's Additional Ability with Anby allows for
                    the last slot of the team to be used more freely on another
                    DPS or Supporter that complements her better instead of
                    being restricted to selecting specifically a character
                    needed to activate the same.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'soldier-11' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="lighter"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Soldier 11 is an accessible and Solid Fire DPS who is more
                    than happy to take up the field time Lighter doesn’t need
                    with her mostly Basic Attack focused gameplay. Lighter
                    offers Soldier 11 all his usual benefits of FIRE DMG%, FIRE
                    RES% SHRED and extended Stun time making the two a solid
                    combo for those not possessing more powerful limited DPS
                    options. Soldier 11 also gains a bit of an additional edge
                    from Lighter compared to others thanks to her Additional
                    Ability functioning specifically against Stunned enemies
                    making Lighter more accessible.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="lucy"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Soldier 11’s Additional Ability is incredibly impactful and
                    such its activation becomes essential, which Lucy does
                    thereby allowing you to have a flexible last slot. Lucy also
                    possesses a strong Attack boost she can grant to the whole
                    team which is highly appreciated by Soldier 11 as it
                    increases her damage output. Lucy’s signature W-engine is
                    also an excellent addition to Soldier 11’s teams granting
                    her a nigh permanent and potent Combat Attack% multiplier.
                    Finally being a Fire character Lucy applies a bit of
                    Anomaly, something which will help keep the enemy Burned to
                    activate Soldier 11’s best Disk Drive set Inferno Metal
                    which is responsible for a large potion of Soldier’s
                    offensive stats.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="koleda"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Koleda is the Stun character of choice for Soldier 11’s best
                    teams. Koleda not only activates Soldier 11’s very important
                    Additional Ability but also her own if it isn’t already
                    activated. Stunning enemies in a timely manner is incredibly
                    important to Soldier 11 as it allows her to attack with
                    Impunity unloading all her Cooldowns, a marathon of Enhanced
                    Basic Attacks all boosted by extra damage to Stunned enemies
                    thanks to her Additional Ability. Because of this,Dazing and
                    Stunning enemies is essential for Soldier 11 and Koleda does
                    it extremely well. In addition to this Koelda being a Fire
                    unit also contributes towards Fire Anomaly build up to
                    assist Soldier 11’s incredibly important Disk Drive bonus
                    Inferno Metal into staying active.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="ben"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Ben is a more F2P accessible option for Soldier 11’s Stun
                    slot compared to Koleda and similarly to her can activate
                    Soldier 11’s Additional Ability while also activating his
                    own. Ben can’t bring as much Daze as Koleda but grants
                    Soldier 11 different benefits to somewhat make up for this,
                    the first of which is a team wide Shield which when built
                    around can serve as a nice buffer against large hits if any
                    taken. Fortunately Ben has more to offer Soldier than just
                    his Shields as when Fire or Belobog allies are Shielded they
                    gain 16% Crit Rate, something which Ben can maintain
                    throughout combat given enough energy regeneration. Ben
                    doesn’t quite match up to Koleda in Soldier’s teams but he
                    is a solid option if you don’t have her or are using her
                    elsewhere.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'koleda' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="ben"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Ben and Koleda have synergy in both Lore and kit, granting
                    each other their Additional Abilities. Beyond that Ben also
                    empowers two of Koleda's best abilities, her Ultimate and
                    Enhanced Basic Attacks. Koleda’s empowered Ultimate has a
                    larger AoE, is easier to aim and also deals more damage and
                    Daze Whereas, the empowered Basic Attack’s “only” gain a
                    good chunk more Daze and damage. Overall, Ben makes Koleda
                    stronger but there is a slight catch to combining the two of
                    them. Both Ben and Koleda are geared toward Stunning enemies
                    as their primary role, something most team’s don’t
                    specifically need two of causing their synergy to come at
                    the cost of a true dedicated Supporter or additional DPS
                    which would likely boost performance more than combining
                    these two together.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="soldier-11"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Koleda is the Stun character of choice for Soldier 11’s best
                    teams. Koleda not only activates Soldier 11’s very important
                    Additional Ability but also her own if it isn’t already
                    activated. Stunning enemies in a timely manner is incredibly
                    important to Soldier 11 as it allows her to attack with
                    Impunity unloading all her Cooldowns, a marathon of Enhanced
                    Basic Attacks all boosted by extra damage to Stunned enemies
                    thanks to her Additional Ability. Because of this,Dazing and
                    Stunning enemies is essential for Soldier 11 and Koleda does
                    it extremely well. In addition to this Koelda being a Fire
                    unit also contributes towards Fire Anomaly build up to
                    assist Soldier 11’s incredibly important Disk Drive bonus
                    Inferno Metal into staying active.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'lycaon' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="ellen"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    With the release of Lighter, Lycaon lost the spot as BIS
                    support for Ellen, but if you don't have Ligher, Lycaon will
                    still work very well with her as he provides Ice RES shred
                    and dishes out incredible amounts of Daze causing Stuns to
                    enemies incredibly quick thereby allowing Ellen to unleash
                    her full EX empowered Basic Combos better than any other
                    character. On top of this Lycaon also increases the Stun
                    multiplier on enemies from 150% to 185% further increasing
                    Ellen’s burst damage. Finally Lycaon and Ellen both deal Ice
                    Anomaly allowing for Freezes to occur more frequently
                    locking up enemies and when lucky even extending Stun
                    durations.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="corin"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Corin is a character fully dedicated to the path of Charged
                    Attacks - something that makes dealing damage outside of
                    Stun windows incredibly difficult for her. On top of this
                    her kit also grants bonus damage against Stunned targets.
                    Thus, Corin is in dire need of a character not only who can
                    Stun quickly but also activating her Additional Ability.
                    Fortunately for her, Lycaon can solve both problems while
                    also amplifying Corin’s damage during Stuns tremendously due
                    to him increasing the Stun damage multiplier on enemies from
                    150% to 185%. Lycaon is the best raw Daze contributor in the
                    game and has great synergy with Corin making the two a good
                    pair as long as no other characters need Lycaon for their
                    own team (looking at you Ellen).
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'corin' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="lycaon"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Corin is a character fully dedicated to the path of Charged
                    Attacks - something that makes dealing damage outside of
                    Stun windows incredibly difficult for her. On top of this
                    her kit also grants bonus damage against Stunned targets.
                    Thus, Corin is in dire need of a character not only who can
                    Stun quickly but also activating her Additional Ability.
                    Fortunately for her, Lycaon can solve both problems while
                    also amplifying Corin’s damage during Stuns tremendously due
                    to him increasing the Stun damage multiplier on enemies from
                    150% to 185%. Lycaon is the best raw Daze contributor in the
                    game and has great synergy with Corin making the two a good
                    pair as long as no other characters need Lycaon for their
                    own team (looking at you Ellen).
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'ben' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="koleda"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Ben and Koleda have synergy in both Lore and kit, granting
                    each other their Additional Abilities. Beyond that Ben also
                    empowers two of Koleda's best abilities, her Ultimate and
                    Enhanced Basic Attacks. Koleda’s empowered Ultimate has a
                    larger AoE, is easier to aim and also deals more damage and
                    Daze Whereas, the empowered Basic Attack’s “only” gain a
                    good chunk more Daze and damage. Overall, Ben makes Koleda
                    stronger but there is a slight catch to combining the two of
                    them. Both Ben and Koleda are geared toward Stunning enemies
                    as their primary role, something most team’s don’t
                    specifically need two of causing their synergy to come at
                    the cost of a true dedicated Supporter or additional DPS
                    which would likely boost performance more than combining
                    these two together..
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="soldier-11"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Ben is a more F2P accessible option for Soldier 11’s Stun
                    slot compared to Koleda and similarly to her can activate
                    Soldier 11’s Additional Ability while also activating his
                    own. Ben can’t bring as much Daze as Koleda but grants
                    Soldier 11 different benefits to somewhat make up for this,
                    the first of which is a team wide Shield which when built
                    around can serve as a nice buffer against large hits if any
                    taken. Fortunately Ben has more to offer Soldier than just
                    his Shields as when Fire or Belobog allies are Shielded they
                    gain 16% Crit Rate, something which Ben can maintain
                    throughout combat given enough energy regeneration. Ben
                    doesn’t quite match up to Koleda in Soldier’s teams but he
                    is a solid option if you don’t have her or are using her
                    elsewhere.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'rina' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="yanagi"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    When playing Yanagi in Electric focused Shock teams Rina
                    makes for a solid choice thanks to her Electric/Shock
                    specific buffs and excellent PEN Ratio bonus which stacks
                    nicely with Yanagi’s “Kagen” Stance PEN Ratio passive. Rina
                    also takes up very little field time allowing Yanagi to
                    combo off as she pleases without restriction. Thanks to
                    Yanagi’s Polarity Disorder - one of the major pain points of
                    pure Shock teams (damage being limited by how many Shock
                    triggers you could achieve) is also diminished.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="grace-howard"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Rina provides Grace with a lot of buffs that are perfect for
                    her. Rina’s first buff grants the entire team a portion of
                    her PEN Ratio + a flat amount up to a cap of 30% - 30% PEN
                    ratio is an incredibly large amount and given PEN Ratio gets
                    stronger the more of it you have, this buff is absolutely
                    nothing to sneeze at - especially when you consider that
                    Rina can keep it active almost permanently with optimal
                    play. Rina’s next buff is a generic 10% Electric DMG
                    increase, but her final buff is the most impactful - it
                    increases all Shocks duration from 10 seconds to 13 seconds,
                    basically extending their damage by 30%.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="anton"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Anton is all about two things - his Burst Mode and ensuring
                    he’s always hitting a Shocked target (preferably a big Shock
                    as well) and Rina absolutely helps with the second one while
                    also bringing Anton a truck load of buffs. One of the major
                    benefits Rina grants Anton is by extending all Shocks by 3
                    seconds - this increases their damage but more importantly
                    for Anton, increases his window for attacking and triggering
                    Shocks before they expire, making him more consistent and
                    comfortable to play. Rina also grants Anton 10% Electric DMG
                    while there is a Shocked enemy on the field and up to 30%
                    PEN ratio as well. As part of the Shock archetype it’s no
                    surprise Rina has outstanding synergy with Anton.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'ellen' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="lighter"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Ice is one of the two elements Lighter specialises in
                    supporting and Ellen is an incredible Ice DPS, so it is of
                    no surprise the two work very well together. The combination
                    of ICE DMG%, ICE RES% Shred, extended Stuns and very low
                    field time requirements gives Ellen everything she ever
                    wanted from a Stunner. With this combo Ellen’s damage is
                    substantially increased and she can freely monopolize the
                    majority of the field time for her lengthy attack combos.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="lycaon"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    With the release of Lighter, Lycaon lost the spot as BIS
                    support for Ellen, but if you don't have Ligher, Lycaon will
                    still work very well with her as he provides Ice RES shred
                    and dishes out incredible amounts of Daze causing Stuns to
                    enemies incredibly quick thereby allowing Ellen to unleash
                    her full EX empowered Basic Combos better than any other
                    character. On top of this Lycaon also increases the Stun
                    multiplier on enemies from 150% to 185% further increasing
                    Ellen’s burst damage. Finally Lycaon and Ellen both deal Ice
                    Anomaly allowing for Freezes to occur more frequently
                    locking up enemies and when lucky even extending Stun
                    durations.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="soukaku"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Soukaku is Ellen’s best support, offering her strong damage
                    gains throughout her kit. The biggest benefit Soukaku offers
                    Ellen is her amazing 500-1000 flat ATK buff which she gains
                    but can transfer to another character via a Chain Attack or
                    Quick Assist. If setup right, it’s incredibly easy to grant
                    Ellen a full powered 1000 ATK bonus right as she begins her
                    burst combo, massively boosting her damage. Soukaku offers
                    Ellen yet more buffs though thanks to her Additional Ability
                    which when played around coorectly will grant Ellen an
                    additional 20% Ice damage on top of all this. Finally
                    Soukaku contributes an excellent amount of Ice anomaly while
                    performing her standard supportive duties, leading to faster
                    and more frequent enemy Freezes.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'soukaku' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="ellen"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Soukaku is Ellen’s best support, offering her strong damage
                    gains throughout her kit. The biggest benefit Soukaku offers
                    Ellen is her amazing 500-1000 flat ATK buff which she gains
                    but can transfer to another character via a Chain Attack or
                    Quick Assist. If setup right, it’s incredibly easy to grant
                    Ellen a full powered 1000 ATK bonus right as she begins her
                    burst combo, massively boosting her damage. Soukaku offers
                    Ellen yet more buffs though thanks to her Additional Ability
                    which when played around coorectly will grant Ellen an
                    additional 20% Ice damage on top of all this. Finally
                    Soukaku contributes an excellent amount of Ice anomaly while
                    performing her standard supportive duties, leading to faster
                    and more frequent enemy Freezes.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'grace-howard' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="jane-doe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Grace excels in applying a lot of Shock while requiring
                    minimal field time - this makes her an ideal partner for
                    Jane. Thank to that, Jane can spend more time on the field
                    and do what she does best - kill things in style.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="rina"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Rina provides Grace with a lot of buffs that are perfect for
                    her. Rina’s first buff grants the entire team a portion of
                    her PEN Ratio + a flat amount up to a cap of 30% - 30% PEN
                    ratio is an incredibly large amount and given PEN Ratio gets
                    stronger the more of it you have, this buff is absolutely
                    nothing to sneeze at - especially when you consider that
                    Rina can keep it active almost permanently with optimal
                    play. Rina’s next buff is a generic 10% Electric DMG
                    increase, but her final buff is the most impactful - it
                    increases all Shocks duration from 10 seconds to 13 seconds,
                    basically extending their damage by 30%.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="anton"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    One of Anton’s kits defining features is his ability to
                    partially trigger any Shock on the target he is hitting
                    every 4th Critical Hit he deals. In order to take most
                    advantage of this ability, Anton wants to attack an enemy
                    with a Shock that is as big as possible so he can trigger it
                    - and who better to help than the Queen of Shocks herself,
                    Grace. Grace is the best character in the game at applying
                    the Shock Anomaly and also applies the largest ones as well
                    making her the perfect accompaniment for Anton.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="anby-demara"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Grace is the backbone of the Shock archetype which is
                    focused on quickly building up as much Electric Anomaly on
                    enemies as you can to keep them Shocked consistently. Anby
                    being an Electric character not only activates both her own
                    and Grace's bonus ability but also contributes her own
                    Anomaly allowing Grace and her team to quickly apply more
                    Shocks, something essential when Anton is a part of the
                    team. Furthermore, Anby also brings Daze to the team, while
                    it isn’t always needed in Shock teams, it's still a viable
                    addition.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'nicole-demara' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="nekomata"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Nicole and Nekomata enable each other’s Additional Ability,
                    opening up the last slot of a team member to your choice.
                    Nicole also provides Nekomata an incredibly impactful
                    Defense Shred debuff on enemies which when timed right can
                    significantly increase Nekomata’s total damage output. On
                    other hand, Nekomata appreciates an ally who can allow her
                    to perform Quick Assist in order to activate her Core
                    Ability and Nicole is not only capable of that but also very
                    good at making it happen as her playstyle revolves around
                    weaving her EX into combat often in order to keep her
                    Defense Shred active. Nicole wants to keep her Defense Shred
                    up using her EX which grants Nekomata a Quick Assist which
                    keeps Nekomata’s buff up ending in a nice synergy giving her
                    the tool’s she needs to deal max damage even when she can’t
                    perform Dodge Counter.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="billy-kid"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Billy’s biggest issue is his lack of access to good
                    synergizing supportive options, especially ones that can
                    enable his Additional Ability if possible. Fortunately for
                    Billy, while there aren’t any Physical supports in 1.0,
                    Nicole is a support who shares a faction with Billy,
                    allowing them both to activate their Additional Abilities
                    with each others help. Nicole also offers Billy other
                    benefits - primarily through her excellent DEF Shredding
                    debuff capabilities. Nicole’s DEF Shred is a great damage
                    amplification tool but due to its low duration it should be
                    deployed before Billy intends to execute burst damage and if
                    possible weaved in between the teams rotation frequently to
                    keep it active as much as possible.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'billy-kid' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="nicole-demara"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Billy’s biggest issue is his lack of access to good
                    synergizing supportive options, especially ones that can
                    enable his Additional Ability if possible. Fortunately for
                    Billy, while there aren’t any Physical supports in 1.0,
                    Nicole is a support who shares a faction with Billy,
                    allowing them both to activate their Additional Abilities
                    with each others help. Nicole also offers Billy other
                    benefits - primarily through her excellent DEF Shredding
                    debuff capabilities. Nicole’s DEF Shred is a great damage
                    amplification tool but due to its low duration it should be
                    deployed before Billy intends to execute burst damage and if
                    possible weaved in between the teams rotation frequently to
                    keep it active as much as possible.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="anby-demara"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    As a Physical DPS Billy has very little access to Supportive
                    Physical options with the other 3 Physical characters on
                    release all being fellow DPS that take up much of the field
                    time Billy would prefer for himself. Pairing Billy with Anby
                    grants him access to his Additional Ability which provides
                    his entire team solid amount of Daze through parries and
                    EX’s, allowing him to run exactly the Supporters he wants.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'anton' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="rina"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Anton is all about two things - his Burst Mode and ensuring
                    he’s always hitting a Shocked target (preferably a big Shock
                    as well) and Rina absolutely helps with the second one while
                    also bringing Anton a truck load of buffs. One of the major
                    benefits Rina grants Anton is by extending all Shocks by 3
                    seconds - this increases their damage but more importantly
                    for Anton, increases his window for attacking and triggering
                    Shocks before they expire, making him more consistent and
                    comfortable to play. Rina also grants Anton 10% Electric DMG
                    while there is a Shocked enemy on the field and up to 30%
                    PEN ratio as well. As part of the Shock archetype it’s no
                    surprise Rina has outstanding synergy with Anton.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="grace-howard"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    One of Anton’s kits defining features is his ability to
                    partially trigger any Shock on the target he is hitting
                    every 4th Critical Hit he deals. In order to take most
                    advantage of this ability, Anton wants to attack an enemy
                    with a Shock that is as big as possible so he can trigger it
                    - and who better to help than the Queen of Shocks herself,
                    Grace. Grace is the best character in the game at applying
                    the Shock Anomaly and also applies the largest ones as well
                    making her the perfect accompaniment for Anton.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'lucy' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="burnice"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Burnice does her best in double DPS teams where she can
                    truly put her off-field damage on display. As such support
                    characters that are able to buff both DPS are more
                    impactful. Lucy is one of such buffers - by being able to
                    apply her Cheer buff to the whole team all at once and
                    maintain it permanently, she grants a significant Flat
                    Attack boost. Furthermore, Lucy requires very little field
                    time of her own granting even more field time to the main
                    DPS of your choice. However, one potential downside of Lucy
                    is that she applies her own Fire Anomaly sometimes,
                    resulting in a slight dilution of Burnice’s own Burns in
                    pure Fire teams or if you’re not able to trigger Disorder
                    fast enough.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="soldier-11"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Soldier 11’s Additional Ability is incredibly impactful and
                    such its activation becomes essential, which Lucy does
                    thereby allowing you to have a flexible last slot. Lucy also
                    possesses a strong Attack boost she can grant to the whole
                    team which is highly appreciated by Soldier 11 as it
                    increases her damage output. Lucy’s signature W-engine is
                    also an excellent addition to Soldier 11’s teams granting
                    her a nigh permanent and potent Combat Attack% multiplier.
                    Finally being a Fire character Lucy applies a bit of
                    Anomaly, something which will help keep the enemy Burned to
                    activate Soldier 11’s best Disk Drive set Inferno Metal
                    which is responsible for a large potion of Soldier’s
                    offensive stats.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="piper"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Due to the nature of Piper’s kit, she wants to spend some
                    time on-field in order to execute her fully charged EX
                    Spinning Slash, meaning she ideally doesn’t want to compete
                    with other damage dealers who would soak up the time she
                    needs. This requirement makes things difficult for Piper as
                    the only other Physical characters she can pair with to
                    activate her Additional Ability are all damage dealers and
                    there is only one other character sharing the same factions
                    as her.
                  </p>
                  <p>
                    Fortunately, the character sharing a faction with her is
                    Lucy who just so happens to be an incredibly good support
                    for Piper, solving her biggest issue of a lack of teammates.
                    Lucy isn’t just there to enable Piper’s Additional Ability
                    though - she also grants Piper a flat 600 ATK boost which
                    she can keep active close to permanently. Lucy also has a
                    great signature W-Engine that boosts the whole teams combat
                    ATK% multiplier, boosting Piper’s damage even further. Both
                    units work well together and cover each others Additional
                    Ability requirements allowing the last team member to be
                    incredibly flexible.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'piper' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="lucy"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Due to the nature of Piper’s kit, she wants to spend some
                    time on-field in order to execute her fully charged EX
                    Spinning Slash, meaning she ideally doesn’t want to compete
                    with other damage dealers who would soak up the time she
                    needs. This requirement makes things difficult for Piper as
                    the only other Physical characters she can pair with to
                    activate her Additional Ability are all damage dealers and
                    there is only one other character sharing the same factions
                    as her.
                  </p>
                  <p>
                    Fortunately, the character sharing a faction with her is
                    Lucy who just so happens to be an incredibly good support
                    for Piper, solving her biggest issue of a lack of teammates.
                    Lucy isn’t just there to enable Piper’s Additional Ability
                    though - she also grants Piper a flat 600 ATK boost which
                    she can keep active close to permanently. Lucy also has a
                    great signature W-Engine that boosts the whole teams combat
                    ATK% multiplier, boosting Piper’s damage even further. Both
                    units work well together and cover each others Additional
                    Ability requirements allowing the last team member to be
                    incredibly flexible.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="burnice"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Alternative primary Anomaly/Disorder DPS teammate for
                    Burnice who can output excellent Physical Anomaly
                    application during her EX-Special attack, triggering some
                    impressive Disorders when aligned correctly. However, she
                    has far more limited options compared to Jane outside of her
                    main EX combo to fill field time, making her somewhat energy
                    hungry but with careful planning she still forms a powerful
                    synergy with the correct third supporting team mate.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'jane-doe' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="burnice"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Jane is a great Anomaly/Disorder DPS partner for Burnice.
                    She utilizes all available field time Burnice grants the
                    team through her incredibly powerful Basic Attack combos,
                    triggering constant Assaults which synergize excellently
                    with Burnice’s frequent Burns to trigger absolutely
                    monstrous Disorders. Both characters also trigger each
                    other's Additional Ability freeing up the final slot for
                    many.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="grace-howard"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Grace excels in applying a lot of Shock while requiring
                    minimal field time - this makes her a great partner for
                    Jane. Thank to that, Jane can spend more time on the field
                    and do what she does best - kill things in style.
                  </p>
                </div>
              </div>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="seth"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Seth is a great support who helps Jane (and other Anomaly
                    characters) and doesn't require to be played on the field
                    for a long time. He applies a generic 20% Anomaly RES shred
                    to enemies - which amplifies all Anomaly build-up against
                    them. This effect is crucial both Jane's and Grace's
                    abilities. On top of that, Seth grants a chosen squad member
                    (based on team positioning) up to 100 additional AP (Anomaly
                    Proficiency). This boost not only enhances Jane’s damage
                    output but also assists her in capping out her Core Passive
                    bonus.
                  </p>
                </div>
              </div>
            </>
          )}
          {name === 'seth' && (
            <>
              <div className="single-character">
                <div className="character-link">
                  <ZZZCharacter
                    mode="icon"
                    slug="jane-doe"
                    enablePopover
                    showLabel
                  />
                </div>
                <div className="character-info">
                  <p>
                    Seth is the current go-to Anomaly support who helps Jane
                    (and other Anomaly characters) and doesn't require to be
                    played on the field for a long time. He applies a generic
                    20% Anomaly RES shred to enemies - which amplifies all
                    Anomaly build-up against them. This effect is crucial both
                    Jane's and Grace's abilities. On top of that, Seth grants a
                    chosen squad member (based on team positioning) up to 100
                    additional AP (Anomaly Proficiency). This boost not only
                    enhances Jane’s damage output but also assists her in
                    capping out her Core Passive bonus.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
