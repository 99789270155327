export const ZZZBuilds = [
  {
    char: 'zhu-yuan',
    app_rate: 45.37,
    avg_round: 2.91,
    std_dev_round: 0.3,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 12.7,
    diff_rounds: 0.02,
    weapon_1: 'Riot Suppressor Mark VI',
    weapon_1_app: 44.28,
    weapon_1_round: 2.94,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 31.45,
    weapon_2_round: 2.91,
    weapon_3: 'The Brimstone',
    weapon_3_app: 12.23,
    weapon_3_round: 2.91,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 5.96,
    weapon_4_round: 2.89,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 3.37,
    weapon_5_round: 2.8,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 1.69,
    weapon_6_round: 2.85,
    weapon_7: 'Street Superstar',
    weapon_7_app: 0.72,
    weapon_7_round: 2.82,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 0.06,
    weapon_8_round: 2.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Chaotic Metal, Woodpecker Electro',
    artifact_1_1: 'Chaotic Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 80.9,
    artifact_1_round: 2.91,
    artifact_2: 'Chaotic Metal, Hormone Punk',
    artifact_2_1: 'Chaotic Metal',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 5.84,
    artifact_2_round: 2.98,
    artifact_3: 'Chaotic Metal, Swing Jazz',
    artifact_3_1: 'Chaotic Metal',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 4.04,
    artifact_3_round: 2.9,
    artifact_4: 'Woodpecker Electro, Chaotic Metal',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Chaotic Metal',
    artifact_4_3: '',
    artifact_4_app: 2.83,
    artifact_4_round: 2.97,
    artifact_5: 'Chaotic Metal, Puffer Electro',
    artifact_5_1: 'Chaotic Metal',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 1.93,
    artifact_5_round: 3.0,
    artifact_6: 'Chaotic Metal, Flex',
    artifact_6_1: 'Chaotic Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 1.27,
    artifact_6_round: 3.0,
    artifact_7: 'Hormone Punk, Woodpecker Electro',
    artifact_7_1: 'Hormone Punk',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 0.78,
    artifact_7_round: 3.0,
    artifact_8: 'Woodpecker Electro, Hormone Punk',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: '',
    artifact_8_app: 0.6,
    artifact_8_round: 3.0,
    artifact_9: 'Hormone Punk, Chaotic Metal',
    artifact_9_1: 'Hormone Punk',
    artifact_9_2: 'Chaotic Metal',
    artifact_9_3: '',
    artifact_9_app: 0.48,
    artifact_9_round: 3.0,
    artifact_10: 'Chaotic Metal, Freedom Blues',
    artifact_10_1: 'Chaotic Metal',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 0.3,
    artifact_10_round: 3.0,
    app_0: 81.08,
    round_0: 2.91,
    app_1: 13.49,
    round_1: 2.97,
    app_2: 2.77,
    round_2: 2.96,
    app_3: 0.36,
    round_3: 3.0,
    app_4: 0.42,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.87,
    round_6: 3.0,
    cons_avg: 0.33,
    sample: 1660,
    sample_app_flat: 1282
  },
  {
    char: 'Burnice',
    app_rate: 49.33,
    avg_round: 2.91,
    std_dev_round: 0.31,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -9.55,
    diff_rounds: 0.0,
    weapon_1: 'Flamemaker Shaker',
    weapon_1_app: 38.12,
    weapon_1_round: 2.94,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 23.43,
    weapon_2_round: 2.89,
    weapon_3: 'Electro-Lip Gloss',
    weapon_3_app: 16.68,
    weapon_3_round: 2.94,
    weapon_4: 'Roaring Ride',
    weapon_4_app: 8.59,
    weapon_4_round: 2.88,
    weapon_5: 'Fusion Compiler',
    weapon_5_app: 6.65,
    weapon_5_round: 2.87,
    weapon_6: 'Rainforest Gourmet',
    weapon_6_app: 6.37,
    weapon_6_round: 2.86,
    weapon_7: '[Magnetic Storm] Alpha',
    weapon_7_app: 0.06,
    weapon_7_round: 3.0,
    weapon_8: 'Slice of Time',
    weapon_8_app: 0.06,
    weapon_8_round: 2.0,
    weapon_9: 'Sharpened Stinger',
    weapon_9_app: 0.06,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Chaos Jazz, Freedom Blues',
    artifact_1_1: 'Chaos Jazz',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 52.85,
    artifact_1_round: 2.9,
    artifact_2: 'Chaos Jazz, Inferno Metal',
    artifact_2_1: 'Chaos Jazz',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 20.78,
    artifact_2_round: 2.91,
    artifact_3: 'Chaos Jazz, Swing Jazz',
    artifact_3_1: 'Chaos Jazz',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 20.17,
    artifact_3_round: 2.93,
    artifact_4: 'Chaos Jazz, Hormone Punk',
    artifact_4_1: 'Chaos Jazz',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 1.72,
    artifact_4_round: 3.0,
    artifact_5: 'Chaos Jazz, Puffer Electro',
    artifact_5_1: 'Chaos Jazz',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 1.16,
    artifact_5_round: 3.0,
    artifact_6: 'Chaos Jazz, Flex',
    artifact_6_1: 'Chaos Jazz',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 0.83,
    artifact_6_round: 2.92,
    artifact_7: 'Chaos Jazz, Woodpecker Electro',
    artifact_7_1: 'Chaos Jazz',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 0.39,
    artifact_7_round: 3.0,
    artifact_8: 'Freedom Blues, Chaos Jazz',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Chaos Jazz',
    artifact_8_3: '',
    artifact_8_app: 0.39,
    artifact_8_round: 2.83,
    artifact_9: 'Inferno Metal, Woodpecker Electro',
    artifact_9_1: 'Inferno Metal',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 0.28,
    artifact_9_round: 3.0,
    artifact_10: 'Inferno Metal, Chaos Jazz',
    artifact_10_1: 'Inferno Metal',
    artifact_10_2: 'Chaos Jazz',
    artifact_10_3: '',
    artifact_10_app: 0.22,
    artifact_10_round: 2.5,
    app_0: 89.58,
    round_0: 2.91,
    app_1: 6.59,
    round_1: 2.92,
    app_2: 2.22,
    round_2: 3.0,
    app_3: 0.55,
    round_3: 3.0,
    app_4: 0.06,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.0,
    round_6: 3.0,
    cons_avg: 0.19,
    sample: 1805,
    sample_app_flat: 1457
  },
  {
    char: 'Yanagi',
    app_rate: 53.95,
    avg_round: 2.91,
    std_dev_round: 0.31,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 7.54,
    diff_rounds: -0.01,
    weapon_1: 'Timeweaver',
    weapon_1_app: 46.91,
    weapon_1_round: 2.95,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 24.52,
    weapon_2_round: 2.87,
    weapon_3: 'Fusion Compiler',
    weapon_3_app: 15.25,
    weapon_3_round: 2.93,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 8.97,
    weapon_4_round: 2.91,
    weapon_5: 'Rainforest Gourmet',
    weapon_5_app: 3.44,
    weapon_5_round: 2.82,
    weapon_6: 'Roaring Ride',
    weapon_6_app: 0.76,
    weapon_6_round: 2.8,
    weapon_7: '[Magnetic Storm] Bravo',
    weapon_7_app: 0.05,
    weapon_7_round: 2.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Chaos Jazz, Freedom Blues',
    artifact_1_1: 'Chaos Jazz',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 66.11,
    artifact_1_round: 2.91,
    artifact_2: 'Thunder Metal, Freedom Blues',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 10.49,
    artifact_2_round: 2.92,
    artifact_3: 'Thunder Metal, Chaos Jazz',
    artifact_3_1: 'Thunder Metal',
    artifact_3_2: 'Chaos Jazz',
    artifact_3_3: '',
    artifact_3_app: 8.21,
    artifact_3_round: 2.9,
    artifact_4: 'Chaos Jazz, Thunder Metal',
    artifact_4_1: 'Chaos Jazz',
    artifact_4_2: 'Thunder Metal',
    artifact_4_3: '',
    artifact_4_app: 4.71,
    artifact_4_round: 2.91,
    artifact_5: 'Chaos Jazz, Puffer Electro',
    artifact_5_1: 'Chaos Jazz',
    artifact_5_2: 'Puffer Electro',
    artifact_5_3: '',
    artifact_5_app: 1.82,
    artifact_5_round: 3.0,
    artifact_6: 'Freedom Blues, Chaos Jazz',
    artifact_6_1: 'Freedom Blues',
    artifact_6_2: 'Chaos Jazz',
    artifact_6_3: '',
    artifact_6_app: 1.77,
    artifact_6_round: 2.88,
    artifact_7: 'Thunder Metal, Puffer Electro',
    artifact_7_1: 'Thunder Metal',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.11,
    artifact_7_round: 3.0,
    artifact_8: 'AP +30, AP +30, Thunder Metal',
    artifact_8_1: 'AP +30',
    artifact_8_2: 'AP +30',
    artifact_8_3: 'Thunder Metal',
    artifact_8_app: 1.01,
    artifact_8_round: 2.79,
    artifact_9: 'Chaos Jazz, Swing Jazz',
    artifact_9_1: 'Chaos Jazz',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: '',
    artifact_9_app: 0.86,
    artifact_9_round: 3.0,
    artifact_10: 'Freedom Blues, Thunder Metal',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Thunder Metal',
    artifact_10_3: '',
    artifact_10_app: 0.51,
    artifact_10_round: 2.86,
    app_0: 87.59,
    round_0: 2.91,
    app_1: 6.23,
    round_1: 2.97,
    app_2: 4.36,
    round_2: 2.98,
    app_3: 0.41,
    round_3: 3.0,
    app_4: 0.05,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.37,
    round_6: 3.0,
    cons_avg: 0.25,
    sample: 1974,
    sample_app_flat: 1617
  },
  {
    char: 'nicole-demara',
    app_rate: 51.16,
    avg_round: 2.9,
    std_dev_round: 0.33,
    role: 'Support',
    rarity: 'A',
    diff: 11.09,
    diff_rounds: 0.02,
    weapon_1: 'The Vault',
    weapon_1_app: 90.97,
    weapon_1_round: 2.9,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 4.7,
    weapon_2_round: 2.89,
    weapon_3: 'Slice of Time',
    weapon_3_app: 1.76,
    weapon_3_round: 2.78,
    weapon_4: 'Kaboom the Cannon',
    weapon_4_app: 1.23,
    weapon_4_round: 2.95,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 0.48,
    weapon_5_round: 3.0,
    weapon_6: '[Reverb] Mark II',
    weapon_6_app: 0.32,
    weapon_6_round: 2.5,
    weapon_7: '[Reverb] Mark III',
    weapon_7_app: 0.27,
    weapon_7_round: 3.0,
    weapon_8: 'Bashful Demon',
    weapon_8_app: 0.05,
    weapon_8_round: 1.0,
    weapon_9: '[Magnetic Storm] Bravo',
    weapon_9_app: 0.05,
    weapon_9_round: 2.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Chaotic Metal',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Chaotic Metal',
    artifact_1_3: '',
    artifact_1_app: 44.82,
    artifact_1_round: 2.91,
    artifact_2: 'Swing Jazz, Freedom Blues',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 27.67,
    artifact_2_round: 2.89,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 4.27,
    artifact_3_round: 2.96,
    artifact_4: 'Swing Jazz, Flex',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 4.11,
    artifact_4_round: 2.92,
    artifact_5: 'Swing Jazz, Woodpecker Electro',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Woodpecker Electro',
    artifact_5_3: '',
    artifact_5_app: 3.26,
    artifact_5_round: 2.98,
    artifact_6: 'Chaotic Metal, Swing Jazz',
    artifact_6_1: 'Chaotic Metal',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 2.88,
    artifact_6_round: 2.72,
    artifact_7: 'Swing Jazz, Hormone Punk',
    artifact_7_1: 'Swing Jazz',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 2.3,
    artifact_7_round: 2.97,
    artifact_8: 'Swing Jazz, Chaos Jazz',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Chaos Jazz',
    artifact_8_3: '',
    artifact_8_app: 1.98,
    artifact_8_round: 2.97,
    artifact_9: 'Swing Jazz, Shockstar Disco',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 1.6,
    artifact_9_round: 2.92,
    artifact_10: 'Chaotic Metal, Swing Jazz, Flex',
    artifact_10_1: 'Chaotic Metal',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.91,
    artifact_10_round: 2.69,
    app_0: 0.27,
    round_0: 2.6,
    app_1: 0.53,
    round_1: 3.0,
    app_2: 1.39,
    round_2: 2.83,
    app_3: 2.14,
    round_3: 2.83,
    app_4: 1.98,
    round_4: 2.72,
    app_5: 3.31,
    round_5: 2.79,
    app_6: 90.38,
    round_6: 2.91,
    cons_avg: 5.76,
    sample: 1872,
    sample_app_flat: 1467
  },
  {
    char: 'Qingyi',
    app_rate: 49.33,
    avg_round: 2.9,
    std_dev_round: 0.32,
    role: 'Stun',
    rarity: 'Limited S',
    diff: 3.75,
    diff_rounds: 0.01,
    weapon_1: 'Ice-Jade Teapot',
    weapon_1_app: 35.46,
    weapon_1_round: 2.9,
    weapon_2: 'Steam Oven',
    weapon_2_app: 22.83,
    weapon_2_round: 2.91,
    weapon_3: 'The Restrained',
    weapon_3_app: 14.24,
    weapon_3_round: 2.9,
    weapon_4: 'Demara Battery Mark II',
    weapon_4_app: 13.19,
    weapon_4_round: 2.9,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 7.2,
    weapon_5_round: 2.89,
    weapon_6: 'Precious Fossilized Core',
    weapon_6_app: 5.37,
    weapon_6_round: 2.83,
    weapon_7: 'Six Shooter',
    weapon_7_app: 1.11,
    weapon_7_round: 3.0,
    weapon_8: '[Vortex] Arrow',
    weapon_8_app: 0.17,
    weapon_8_round: 3.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 40.0,
    artifact_1_round: 2.91,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 38.95,
    artifact_2_round: 2.88,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 12.69,
    artifact_3_round: 2.95,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 1.77,
    artifact_4_round: 3.0,
    artifact_5: 'Shockstar Disco, Hormone Punk',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 1.39,
    artifact_5_round: 2.88,
    artifact_6: 'Shockstar Disco, Freedom Blues',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Freedom Blues',
    artifact_6_3: '',
    artifact_6_app: 1.27,
    artifact_6_round: 3.0,
    artifact_7: 'Shockstar Disco, Puffer Electro',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.16,
    artifact_7_round: 2.94,
    artifact_8: 'Thunder Metal, Shockstar Disco',
    artifact_8_1: 'Thunder Metal',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 0.5,
    artifact_8_round: 2.62,
    artifact_9: 'Shockstar Disco, Thunder Metal, Flex',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: 'Flex',
    artifact_9_app: 0.22,
    artifact_9_round: 2.75,
    artifact_10: 'Shockstar Disco, Chaos Jazz',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Chaos Jazz',
    artifact_10_3: '',
    artifact_10_app: 0.22,
    artifact_10_round: 3.0,
    app_0: 86.48,
    round_0: 2.9,
    app_1: 8.98,
    round_1: 2.99,
    app_2: 2.44,
    round_2: 3.0,
    app_3: 0.44,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.66,
    round_6: 2.97,
    cons_avg: 0.25,
    sample: 1805,
    sample_app_flat: 1344
  },
  {
    char: 'Caesar',
    app_rate: 47.85,
    avg_round: 2.89,
    std_dev_round: 0.34,
    role: 'Stun',
    rarity: 'Limited S',
    diff: -7.2,
    diff_rounds: 0.02,
    weapon_1: 'Tusks of Fury',
    weapon_1_app: 49.23,
    weapon_1_round: 2.92,
    weapon_2: 'Original Transmorpher',
    weapon_2_app: 19.13,
    weapon_2_round: 2.82,
    weapon_3: 'Demara Battery Mark II',
    weapon_3_app: 9.99,
    weapon_3_round: 2.9,
    weapon_4: 'Precious Fossilized Core',
    weapon_4_app: 7.08,
    weapon_4_round: 2.92,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 4.23,
    weapon_5_round: 2.94,
    weapon_6: 'The Restrained',
    weapon_6_app: 4.0,
    weapon_6_round: 2.96,
    weapon_7: 'Spring Embrace',
    weapon_7_app: 2.46,
    weapon_7_round: 2.92,
    weapon_8: 'Peacekeeper - Specialized',
    weapon_8_app: 1.77,
    weapon_8_round: 2.96,
    weapon_9: 'Six Shooter',
    weapon_9_app: 0.63,
    weapon_9_round: 2.89,
    weapon_10: 'Bunny Band',
    weapon_10_app: 0.63,
    weapon_10_round: 2.56,
    artifact_1: 'Proto Punk, Shockstar Disco',
    artifact_1_1: 'Proto Punk',
    artifact_1_2: 'Shockstar Disco',
    artifact_1_3: '',
    artifact_1_app: 83.89,
    artifact_1_round: 2.9,
    artifact_2: 'Proto Punk, Swing Jazz',
    artifact_2_1: 'Proto Punk',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 8.0,
    artifact_2_round: 2.91,
    artifact_3: 'Freedom Blues, Shockstar Disco',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Shockstar Disco',
    artifact_3_3: '',
    artifact_3_app: 1.71,
    artifact_3_round: 2.76,
    artifact_4: 'Swing Jazz, Shockstar Disco',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Shockstar Disco',
    artifact_4_3: '',
    artifact_4_app: 0.57,
    artifact_4_round: 2.71,
    artifact_5: 'Proto Punk, Fanged Metal',
    artifact_5_1: 'Proto Punk',
    artifact_5_2: 'Fanged Metal',
    artifact_5_3: '',
    artifact_5_app: 0.51,
    artifact_5_round: 3.0,
    artifact_6: 'Shockstar Disco, Proto Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Proto Punk',
    artifact_6_3: '',
    artifact_6_app: 0.51,
    artifact_6_round: 3.0,
    artifact_7: 'Proto Punk, Flex',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 0.46,
    artifact_7_round: 2.71,
    artifact_8: 'Shockstar Disco, Swing Jazz',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: '',
    artifact_8_app: 0.4,
    artifact_8_round: 2.8,
    artifact_9: 'Proto Punk, Woodpecker Electro',
    artifact_9_1: 'Proto Punk',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 0.4,
    artifact_9_round: 3.0,
    artifact_10: 'Proto Punk, Freedom Blues',
    artifact_10_1: 'Proto Punk',
    artifact_10_2: 'Freedom Blues',
    artifact_10_3: '',
    artifact_10_app: 0.4,
    artifact_10_round: 2.8,
    app_0: 89.15,
    round_0: 2.89,
    app_1: 6.17,
    round_1: 2.94,
    app_2: 3.14,
    round_2: 3.0,
    app_3: 0.51,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.03,
    round_6: 2.94,
    cons_avg: 0.2,
    sample: 1751,
    sample_app_flat: 1325
  },
  {
    char: 'Rina',
    app_rate: 25.88,
    avg_round: 2.87,
    std_dev_round: 0.37,
    role: 'Support',
    rarity: 'Standard S',
    diff: -1.76,
    diff_rounds: -0.04,
    weapon_1: 'Slice of Time',
    weapon_1_app: 49.95,
    weapon_1_round: 2.88,
    weapon_2: 'Weeping Cradle',
    weapon_2_app: 44.46,
    weapon_2_round: 2.89,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 3.38,
    weapon_3_round: 2.82,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 0.95,
    weapon_4_round: 2.86,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 0.95,
    weapon_5_round: 2.43,
    weapon_6: '[Reverb] Mark III',
    weapon_6_app: 0.11,
    weapon_6_round: 3.0,
    weapon_7: 'Fusion Compiler',
    weapon_7_app: 0.11,
    weapon_7_round: 3.0,
    weapon_8: 'The Vault',
    weapon_8_app: 0.11,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Puffer Electro',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Puffer Electro',
    artifact_1_3: '',
    artifact_1_app: 58.92,
    artifact_1_round: 2.88,
    artifact_2: 'Freedom Blues, Puffer Electro',
    artifact_2_1: 'Freedom Blues',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 15.84,
    artifact_2_round: 2.94,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 4.44,
    artifact_3_round: 2.89,
    artifact_4: 'Swing Jazz, Freedom Blues',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Freedom Blues',
    artifact_4_3: '',
    artifact_4_app: 3.59,
    artifact_4_round: 2.77,
    artifact_5: 'Swing Jazz, Thunder Metal',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Thunder Metal',
    artifact_5_3: '',
    artifact_5_app: 1.37,
    artifact_5_round: 2.6,
    artifact_6: 'Swing Jazz, Flex',
    artifact_6_1: 'Swing Jazz',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 1.27,
    artifact_6_round: 2.9,
    artifact_7: 'Freedom Blues, Thunder Metal',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Thunder Metal',
    artifact_7_3: '',
    artifact_7_app: 1.06,
    artifact_7_round: 3.0,
    artifact_8: 'Puffer Electro, Swing Jazz, Flex',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Swing Jazz',
    artifact_8_3: 'Flex',
    artifact_8_app: 0.95,
    artifact_8_round: 3.0,
    artifact_9: 'Proto Punk, Puffer Electro',
    artifact_9_1: 'Proto Punk',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 0.95,
    artifact_9_round: 3.0,
    artifact_10: 'Freedom Blues, Flex',
    artifact_10_1: 'Freedom Blues',
    artifact_10_2: 'Flex',
    artifact_10_3: '',
    artifact_10_app: 0.95,
    artifact_10_round: 2.71,
    app_0: 52.8,
    round_0: 2.87,
    app_1: 34.11,
    round_1: 2.88,
    app_2: 9.4,
    round_2: 2.88,
    app_3: 2.64,
    round_3: 3.0,
    app_4: 0.84,
    round_4: 3.0,
    app_5: 0.11,
    round_5: 1.0,
    app_6: 0.11,
    round_6: 1.0,
    cons_avg: 0.65,
    sample: 947,
    sample_app_flat: 751
  },
  {
    char: 'Lucy',
    app_rate: 34.22,
    avg_round: 2.86,
    std_dev_round: 0.4,
    role: 'Support',
    rarity: 'A',
    diff: -18.11,
    diff_rounds: -0.01,
    weapon_1: 'Kaboom the Cannon',
    weapon_1_app: 87.14,
    weapon_1_round: 2.87,
    weapon_2: 'Slice of Time',
    weapon_2_app: 3.99,
    weapon_2_round: 2.8,
    weapon_3: 'Weeping Cradle',
    weapon_3_app: 3.04,
    weapon_3_round: 2.88,
    weapon_4: '[Reverb] Mark II',
    weapon_4_app: 2.72,
    weapon_4_round: 2.84,
    weapon_5: 'Unfettered Game Ball',
    weapon_5_app: 0.96,
    weapon_5_round: 2.8,
    weapon_6: '[Reverb] Mark III',
    weapon_6_app: 0.72,
    weapon_6_round: 2.56,
    weapon_7: 'Bashful Demon',
    weapon_7_app: 0.4,
    weapon_7_round: 3.0,
    weapon_8: '[Reverb] Mark I',
    weapon_8_app: 0.24,
    weapon_8_round: 2.67,
    weapon_9: 'The Vault',
    weapon_9_app: 0.24,
    weapon_9_round: 3.0,
    weapon_10: 'The Brimstone',
    weapon_10_app: 0.08,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 59.19,
    artifact_1_round: 2.86,
    artifact_2: 'Swing Jazz, Woodpecker Electro',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Woodpecker Electro',
    artifact_2_3: '',
    artifact_2_app: 8.31,
    artifact_2_round: 2.93,
    artifact_3: 'Swing Jazz, Freedom Blues',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Freedom Blues',
    artifact_3_3: '',
    artifact_3_app: 4.87,
    artifact_3_round: 2.72,
    artifact_4: 'Swing Jazz, Flex',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 3.51,
    artifact_4_round: 2.86,
    artifact_5: 'Swing Jazz, Inferno Metal',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Inferno Metal',
    artifact_5_3: '',
    artifact_5_app: 3.27,
    artifact_5_round: 2.8,
    artifact_6: 'Hormone Punk, Swing Jazz',
    artifact_6_1: 'Hormone Punk',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 2.88,
    artifact_6_round: 2.78,
    artifact_7: 'Freedom Blues, Swing Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 2.16,
    artifact_7_round: 2.83,
    artifact_8: 'Swing Jazz, Shockstar Disco',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 1.44,
    artifact_8_round: 2.8,
    artifact_9: 'Swing Jazz, Chaos Jazz',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Chaos Jazz',
    artifact_9_3: '',
    artifact_9_app: 1.28,
    artifact_9_round: 3.0,
    artifact_10: 'Proto Punk, Swing Jazz',
    artifact_10_1: 'Proto Punk',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 1.2,
    artifact_10_round: 3.0,
    app_0: 0.64,
    round_0: 2.5,
    app_1: 1.44,
    round_1: 2.53,
    app_2: 5.67,
    round_2: 2.7,
    app_3: 7.11,
    round_3: 2.77,
    app_4: 8.07,
    round_4: 2.8,
    app_5: 8.23,
    round_5: 2.81,
    app_6: 68.85,
    round_6: 2.91,
    cons_avg: 5.21,
    sample: 1252,
    sample_app_flat: 1055
  },
  {
    char: 'Lycaon',
    app_rate: 30.06,
    avg_round: 2.83,
    std_dev_round: 0.43,
    role: 'Stun',
    rarity: 'Standard S',
    diff: 13.41,
    diff_rounds: 0.07,
    weapon_1: 'The Restrained',
    weapon_1_app: 35.0,
    weapon_1_round: 2.85,
    weapon_2: 'Steam Oven',
    weapon_2_app: 25.09,
    weapon_2_round: 2.79,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 17.91,
    weapon_3_round: 2.84,
    weapon_4: 'Hellfire Gears',
    weapon_4_app: 14.27,
    weapon_4_round: 2.89,
    weapon_5: 'Six Shooter',
    weapon_5_app: 4.64,
    weapon_5_round: 2.77,
    weapon_6: 'Demara Battery Mark II',
    weapon_6_app: 1.09,
    weapon_6_round: 2.78,
    weapon_7: '[Vortex] Arrow',
    weapon_7_app: 0.64,
    weapon_7_round: 2.71,
    weapon_8: 'Blazing Laurel',
    weapon_8_app: 0.27,
    weapon_8_round: 3.0,
    weapon_9: '[Vortex] Revolver',
    weapon_9_app: 0.18,
    weapon_9_round: 2.5,
    weapon_10: 'Starlight Engine Replica',
    weapon_10_app: 0.09,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 43.55,
    artifact_1_round: 2.87,
    artifact_2: 'Shockstar Disco, Polar Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 34.36,
    artifact_2_round: 2.79,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 6.45,
    artifact_3_round: 2.91,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 3.36,
    artifact_4_round: 2.8,
    artifact_5: 'Swing Jazz, Shockstar Disco',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Shockstar Disco',
    artifact_5_3: '',
    artifact_5_app: 1.91,
    artifact_5_round: 3.0,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.36,
    artifact_6_round: 2.71,
    artifact_7: 'Polar Metal, Shockstar Disco',
    artifact_7_1: 'Polar Metal',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 1.27,
    artifact_7_round: 2.27,
    artifact_8: 'Freedom Blues, Shockstar Disco',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: '',
    artifact_8_app: 1.18,
    artifact_8_round: 3.0,
    artifact_9: 'Shockstar Disco, Freedom Blues',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Freedom Blues',
    artifact_9_3: '',
    artifact_9_app: 1.09,
    artifact_9_round: 2.88,
    artifact_10: 'Shockstar Disco, Puffer Electro',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Puffer Electro',
    artifact_10_3: '',
    artifact_10_app: 0.82,
    artifact_10_round: 2.86,
    app_0: 61.27,
    round_0: 2.85,
    app_1: 27.36,
    round_1: 2.79,
    app_2: 8.45,
    round_2: 2.9,
    app_3: 1.82,
    round_3: 2.78,
    app_4: 0.64,
    round_4: 3.0,
    app_5: 0.36,
    round_5: 1.0,
    app_6: 0.09,
    round_6: 1.0,
    cons_avg: 0.55,
    sample: 1100,
    sample_app_flat: 796
  },
  {
    char: 'Ellen',
    app_rate: 48.57,
    avg_round: 2.82,
    std_dev_round: 0.43,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: 21.82,
    diff_rounds: 0.06,
    weapon_1: 'Deep Sea Visitor',
    weapon_1_app: 70.23,
    weapon_1_round: 2.87,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 9.57,
    weapon_2_round: 2.72,
    weapon_3: 'The Brimstone',
    weapon_3_app: 6.53,
    weapon_3_round: 2.74,
    weapon_4: 'Cannon Rotor',
    weapon_4_app: 4.78,
    weapon_4_round: 2.8,
    weapon_5: 'Gilded Blossom',
    weapon_5_app: 3.83,
    weapon_5_round: 2.72,
    weapon_6: 'Steel Cushion',
    weapon_6_app: 3.6,
    weapon_6_round: 2.8,
    weapon_7: 'Street Superstar',
    weapon_7_app: 0.84,
    weapon_7_round: 2.62,
    weapon_8: 'Riot Suppressor Mark VI',
    weapon_8_app: 0.34,
    weapon_8_round: 3.0,
    weapon_9: '[Lunar] Noviluna',
    weapon_9_app: 0.11,
    weapon_9_round: 2.5,
    weapon_10: 'Housekeeper',
    weapon_10_app: 0.06,
    weapon_10_round: 3.0,
    artifact_1: 'Polar Metal, Woodpecker Electro',
    artifact_1_1: 'Polar Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 58.53,
    artifact_1_round: 2.79,
    artifact_2: 'Woodpecker Electro, Polar Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 12.16,
    artifact_2_round: 2.88,
    artifact_3: 'Woodpecker Electro, Puffer Electro',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Puffer Electro',
    artifact_3_3: '',
    artifact_3_app: 10.24,
    artifact_3_round: 2.89,
    artifact_4: 'Polar Metal, Puffer Electro',
    artifact_4_1: 'Polar Metal',
    artifact_4_2: 'Puffer Electro',
    artifact_4_3: '',
    artifact_4_app: 9.51,
    artifact_4_round: 2.89,
    artifact_5: 'Polar Metal, Hormone Punk',
    artifact_5_1: 'Polar Metal',
    artifact_5_2: 'Hormone Punk',
    artifact_5_3: '',
    artifact_5_app: 3.26,
    artifact_5_round: 2.88,
    artifact_6: 'Woodpecker Electro, Hormone Punk',
    artifact_6_1: 'Woodpecker Electro',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 1.69,
    artifact_6_round: 2.83,
    artifact_7: 'Polar Metal, Flex',
    artifact_7_1: 'Polar Metal',
    artifact_7_2: 'Flex',
    artifact_7_3: '',
    artifact_7_app: 1.01,
    artifact_7_round: 2.62,
    artifact_8: 'Puffer Electro, Woodpecker Electro',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Woodpecker Electro',
    artifact_8_3: '',
    artifact_8_app: 0.68,
    artifact_8_round: 2.78,
    artifact_9: 'Woodpecker Electro, Flex',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 0.45,
    artifact_9_round: 3.0,
    artifact_10: 'Polar Metal, Swing Jazz',
    artifact_10_1: 'Polar Metal',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 0.39,
    artifact_10_round: 3.0,
    app_0: 72.71,
    round_0: 2.82,
    app_1: 15.14,
    round_1: 2.91,
    app_2: 7.71,
    round_2: 2.96,
    app_3: 1.01,
    round_3: 3.0,
    app_4: 0.17,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 3.26,
    round_6: 3.0,
    cons_avg: 0.54,
    sample: 1777,
    sample_app_flat: 1255
  },
  {
    char: 'jane-doe',
    app_rate: 25.44,
    avg_round: 2.82,
    std_dev_round: 0.44,
    role: 'Damage Dealer',
    rarity: 'Limited S',
    diff: -31.49,
    diff_rounds: -0.05,
    weapon_1: 'Sharpened Stinger',
    weapon_1_app: 57.68,
    weapon_1_round: 2.86,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 15.15,
    weapon_2_round: 2.81,
    weapon_3: 'Rainforest Gourmet',
    weapon_3_app: 14.82,
    weapon_3_round: 2.72,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 7.63,
    weapon_4_round: 2.85,
    weapon_5: 'Fusion Compiler',
    weapon_5_app: 3.65,
    weapon_5_round: 2.62,
    weapon_6: 'Roaring Ride',
    weapon_6_app: 0.86,
    weapon_6_round: 2.86,
    weapon_7: 'Flamemaker Shaker',
    weapon_7_app: 0.11,
    weapon_7_round: 2.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 87.43,
    artifact_1_round: 2.82,
    artifact_2: 'Fanged Metal, Chaos Jazz',
    artifact_2_1: 'Fanged Metal',
    artifact_2_2: 'Chaos Jazz',
    artifact_2_3: '',
    artifact_2_app: 5.8,
    artifact_2_round: 2.83,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 3.11,
    artifact_3_round: 3.0,
    artifact_4: 'Freedom Blues, Fanged Metal',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Fanged Metal',
    artifact_4_3: '',
    artifact_4_app: 0.75,
    artifact_4_round: 1.6,
    artifact_5: 'Fanged Metal, Flex',
    artifact_5_1: 'Fanged Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 0.43,
    artifact_5_round: 2.5,
    artifact_6: 'Fanged Metal, Puffer Electro',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Puffer Electro',
    artifact_6_3: '',
    artifact_6_app: 0.32,
    artifact_6_round: 3.0,
    artifact_7: 'AP +30, AP +30, Fanged Metal',
    artifact_7_1: 'AP +30',
    artifact_7_2: 'AP +30',
    artifact_7_3: 'Fanged Metal',
    artifact_7_app: 0.32,
    artifact_7_round: 2.67,
    artifact_8: 'Freedom Blues, Chaos Jazz',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Chaos Jazz',
    artifact_8_3: '',
    artifact_8_app: 0.32,
    artifact_8_round: 3.0,
    artifact_9: 'Freedom Blues, Swing Jazz',
    artifact_9_1: 'Freedom Blues',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: '',
    artifact_9_app: 0.21,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Woodpecker Electro, Flex',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.11,
    artifact_10_round: 3.0,
    app_0: 78.63,
    round_0: 2.82,
    app_1: 9.88,
    round_1: 2.89,
    app_2: 8.59,
    round_2: 2.99,
    app_3: 0.75,
    round_3: 3.0,
    app_4: 0.32,
    round_4: 3.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.83,
    round_6: 3.0,
    cons_avg: 0.42,
    sample: 931,
    sample_app_flat: 703
  },
  {
    char: 'Seth',
    app_rate: 28.75,
    avg_round: 2.82,
    std_dev_round: 0.43,
    role: 'Support',
    rarity: 'A',
    diff: -10.87,
    diff_rounds: -0.04,
    weapon_1: 'Peacekeeper - Specialized',
    weapon_1_app: 76.81,
    weapon_1_round: 2.84,
    weapon_2: 'Bunny Band',
    weapon_2_app: 6.65,
    weapon_2_round: 2.75,
    weapon_3: 'Spring Embrace',
    weapon_3_app: 5.99,
    weapon_3_round: 2.8,
    weapon_4: 'Original Transmorpher',
    weapon_4_app: 3.14,
    weapon_4_round: 2.57,
    weapon_5: 'Big Cylinder',
    weapon_5_app: 1.52,
    weapon_5_round: 2.64,
    weapon_6: 'Starlight Engine',
    weapon_6_app: 1.05,
    weapon_6_round: 2.8,
    weapon_7: 'Gilded Blossom',
    weapon_7_app: 0.76,
    weapon_7_round: 3.0,
    weapon_8: 'Tusks of Fury',
    weapon_8_app: 0.76,
    weapon_8_round: 3.0,
    weapon_9: 'Street Superstar',
    weapon_9_app: 0.48,
    weapon_9_round: 3.0,
    weapon_10: 'Bashful Demon',
    weapon_10_app: 0.29,
    weapon_10_round: 3.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 33.75,
    artifact_1_round: 2.77,
    artifact_2: 'Proto Punk, Hormone Punk',
    artifact_2_1: 'Proto Punk',
    artifact_2_2: 'Hormone Punk',
    artifact_2_3: '',
    artifact_2_app: 14.16,
    artifact_2_round: 2.86,
    artifact_3: 'Proto Punk, Swing Jazz',
    artifact_3_1: 'Proto Punk',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 7.7,
    artifact_3_round: 2.79,
    artifact_4: 'Swing Jazz, Freedom Blues',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Freedom Blues',
    artifact_4_3: '',
    artifact_4_app: 6.37,
    artifact_4_round: 2.85,
    artifact_5: 'Proto Punk, Shockstar Disco',
    artifact_5_1: 'Proto Punk',
    artifact_5_2: 'Shockstar Disco',
    artifact_5_3: '',
    artifact_5_app: 4.09,
    artifact_5_round: 3.0,
    artifact_6: 'Freedom Blues, Swing Jazz',
    artifact_6_1: 'Freedom Blues',
    artifact_6_2: 'Swing Jazz',
    artifact_6_3: '',
    artifact_6_app: 3.71,
    artifact_6_round: 2.97,
    artifact_7: 'Freedom Blues, Hormone Punk',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 3.33,
    artifact_7_round: 2.77,
    artifact_8: 'Proto Punk, Freedom Blues',
    artifact_8_1: 'Proto Punk',
    artifact_8_2: 'Freedom Blues',
    artifact_8_3: '',
    artifact_8_app: 2.76,
    artifact_8_round: 2.96,
    artifact_9: 'Hormone Punk, Swing Jazz',
    artifact_9_1: 'Hormone Punk',
    artifact_9_2: 'Swing Jazz',
    artifact_9_3: '',
    artifact_9_app: 2.09,
    artifact_9_round: 2.72,
    artifact_10: 'Swing Jazz, Proto Punk',
    artifact_10_1: 'Swing Jazz',
    artifact_10_2: 'Proto Punk',
    artifact_10_3: '',
    artifact_10_app: 1.43,
    artifact_10_round: 2.82,
    app_0: 5.13,
    round_0: 2.78,
    app_1: 10.55,
    round_1: 2.82,
    app_2: 12.36,
    round_2: 2.83,
    app_3: 12.74,
    round_3: 2.82,
    app_4: 13.69,
    round_4: 2.84,
    app_5: 9.41,
    round_5: 2.76,
    app_6: 36.12,
    round_6: 2.84,
    cons_avg: 3.92,
    sample: 1052,
    sample_app_flat: 866
  },
  {
    char: 'Lighter',
    app_rate: 11.01,
    avg_round: 2.82,
    std_dev_round: 0.43,
    role: 'Stun',
    rarity: 'Limited S',
    diff: 0.0,
    diff_rounds: 1,
    weapon_1: 'Blazing Laurel',
    weapon_1_app: 52.36,
    weapon_1_round: 2.83,
    weapon_2: 'Hellfire Gears',
    weapon_2_app: 17.37,
    weapon_2_round: 2.86,
    weapon_3: 'Steam Oven',
    weapon_3_app: 10.67,
    weapon_3_round: 2.82,
    weapon_4: 'Precious Fossilized Core',
    weapon_4_app: 9.18,
    weapon_4_round: 2.8,
    weapon_5: 'The Restrained',
    weapon_5_app: 7.94,
    weapon_5_round: 2.7,
    weapon_6: 'Six Shooter',
    weapon_6_app: 1.24,
    weapon_6_round: 3.0,
    weapon_7: 'Ice-Jade Teapot',
    weapon_7_app: 0.74,
    weapon_7_round: 3.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 50.87,
    artifact_1_round: 2.8,
    artifact_2: 'Shockstar Disco, Inferno Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 16.13,
    artifact_2_round: 2.78,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 11.17,
    artifact_3_round: 2.91,
    artifact_4: 'Inferno Metal, Shockstar Disco',
    artifact_4_1: 'Inferno Metal',
    artifact_4_2: 'Shockstar Disco',
    artifact_4_3: '',
    artifact_4_app: 4.96,
    artifact_4_round: 2.85,
    artifact_5: 'Proto Punk, Shockstar Disco',
    artifact_5_1: 'Proto Punk',
    artifact_5_2: 'Shockstar Disco',
    artifact_5_3: '',
    artifact_5_app: 2.23,
    artifact_5_round: 3.0,
    artifact_6: 'Woodpecker Electro, Shockstar Disco',
    artifact_6_1: 'Woodpecker Electro',
    artifact_6_2: 'Shockstar Disco',
    artifact_6_3: '',
    artifact_6_app: 1.99,
    artifact_6_round: 3.0,
    artifact_7: 'Inferno Metal, Woodpecker Electro',
    artifact_7_1: 'Inferno Metal',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: '',
    artifact_7_app: 1.99,
    artifact_7_round: 3.0,
    artifact_8: 'Shockstar Disco, Flex',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Flex',
    artifact_8_3: '',
    artifact_8_app: 1.74,
    artifact_8_round: 2.75,
    artifact_9: 'Shockstar Disco, Hormone Punk',
    artifact_9_1: 'Shockstar Disco',
    artifact_9_2: 'Hormone Punk',
    artifact_9_3: '',
    artifact_9_app: 1.74,
    artifact_9_round: 3.0,
    artifact_10: 'Shockstar Disco, Puffer Electro',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Puffer Electro',
    artifact_10_3: '',
    artifact_10_app: 1.24,
    artifact_10_round: 3.0,
    app_0: 88.09,
    round_0: 2.82,
    app_1: 5.71,
    round_1: 2.96,
    app_2: 2.98,
    round_2: 2.92,
    app_3: 1.24,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 1.99,
    round_6: 3.0,
    cons_avg: 0.27,
    sample: 403,
    sample_app_flat: 275
  },
  {
    char: 'Soukaku',
    app_rate: 40.78,
    avg_round: 2.81,
    std_dev_round: 0.45,
    role: 'Support',
    rarity: 'A',
    diff: 15.25,
    diff_rounds: 0.05,
    weapon_1: 'Bashful Demon',
    weapon_1_app: 93.43,
    weapon_1_round: 2.81,
    weapon_2: 'Slice of Time',
    weapon_2_app: 2.61,
    weapon_2_round: 2.63,
    weapon_3: 'Kaboom the Cannon',
    weapon_3_app: 1.54,
    weapon_3_round: 2.76,
    weapon_4: 'Unfettered Game Ball',
    weapon_4_app: 0.74,
    weapon_4_round: 2.82,
    weapon_5: 'Weeping Cradle',
    weapon_5_app: 0.6,
    weapon_5_round: 3.0,
    weapon_6: '[Reverb] Mark II',
    weapon_6_app: 0.54,
    weapon_6_round: 2.5,
    weapon_7: '[Reverb] Mark III',
    weapon_7_app: 0.34,
    weapon_7_round: 3.0,
    weapon_8: '[Reverb] Mark I',
    weapon_8_app: 0.2,
    weapon_8_round: 2.67,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Hormone Punk',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Hormone Punk',
    artifact_1_3: '',
    artifact_1_app: 54.89,
    artifact_1_round: 2.81,
    artifact_2: 'Swing Jazz, Polar Metal',
    artifact_2_1: 'Swing Jazz',
    artifact_2_2: 'Polar Metal',
    artifact_2_3: '',
    artifact_2_app: 11.73,
    artifact_2_round: 2.85,
    artifact_3: 'Swing Jazz, Woodpecker Electro',
    artifact_3_1: 'Swing Jazz',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 3.89,
    artifact_3_round: 2.91,
    artifact_4: 'Swing Jazz, Flex',
    artifact_4_1: 'Swing Jazz',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 3.55,
    artifact_4_round: 2.8,
    artifact_5: 'Swing Jazz, Freedom Blues',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 3.02,
    artifact_5_round: 2.7,
    artifact_6: 'Polar Metal, Woodpecker Electro',
    artifact_6_1: 'Polar Metal',
    artifact_6_2: 'Woodpecker Electro',
    artifact_6_3: '',
    artifact_6_app: 2.28,
    artifact_6_round: 2.87,
    artifact_7: 'Freedom Blues, Swing Jazz',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Swing Jazz',
    artifact_7_3: '',
    artifact_7_app: 2.28,
    artifact_7_round: 2.92,
    artifact_8: 'Hormone Punk, Polar Metal',
    artifact_8_1: 'Hormone Punk',
    artifact_8_2: 'Polar Metal',
    artifact_8_3: '',
    artifact_8_app: 1.41,
    artifact_8_round: 2.69,
    artifact_9: 'Swing Jazz, Puffer Electro',
    artifact_9_1: 'Swing Jazz',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.34,
    artifact_9_round: 2.71,
    artifact_10: 'Hormone Punk, Swing Jazz',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 1.27,
    artifact_10_round: 2.71,
    app_0: 0.13,
    round_0: 2.0,
    app_1: 0.54,
    round_1: 2.38,
    app_2: 1.54,
    round_2: 2.77,
    app_3: 2.14,
    round_3: 2.6,
    app_4: 5.36,
    round_4: 2.83,
    app_5: 6.77,
    round_5: 2.83,
    app_6: 83.51,
    round_6: 2.82,
    cons_avg: 5.66,
    sample: 1492,
    sample_app_flat: 1111
  },
  {
    char: 'Koleda',
    app_rate: 5.77,
    avg_round: 2.8,
    std_dev_round: 0.44,
    role: 'Stun',
    rarity: 'Standard S',
    diff: -0.71,
    diff_rounds: 0.07,
    weapon_1: 'Hellfire Gears',
    weapon_1_app: 37.44,
    weapon_1_round: 2.79,
    weapon_2: 'Steam Oven',
    weapon_2_app: 32.7,
    weapon_2_round: 2.79,
    weapon_3: 'Precious Fossilized Core',
    weapon_3_app: 12.32,
    weapon_3_round: 2.88,
    weapon_4: 'The Restrained',
    weapon_4_app: 5.69,
    weapon_4_round: 3.0,
    weapon_5: 'Six Shooter',
    weapon_5_app: 5.69,
    weapon_5_round: 2.6,
    weapon_6: 'Demara Battery Mark II',
    weapon_6_app: 3.79,
    weapon_6_round: 2.62,
    weapon_7: '[Vortex] Revolver',
    weapon_7_app: 0.95,
    weapon_7_round: 3.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 69.19,
    artifact_1_round: 2.77,
    artifact_2: 'Shockstar Disco, Inferno Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Inferno Metal',
    artifact_2_3: '',
    artifact_2_app: 9.48,
    artifact_2_round: 2.87,
    artifact_3: 'Shockstar Disco, Flex',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 4.74,
    artifact_3_round: 2.78,
    artifact_4: 'Shockstar Disco, Woodpecker Electro',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Woodpecker Electro',
    artifact_4_3: '',
    artifact_4_app: 4.27,
    artifact_4_round: 3.0,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 3.32,
    artifact_5_round: 3.0,
    artifact_6: 'Proto Punk, Shockstar Disco',
    artifact_6_1: 'Proto Punk',
    artifact_6_2: 'Shockstar Disco',
    artifact_6_3: '',
    artifact_6_app: 1.42,
    artifact_6_round: 3.0,
    artifact_7: 'Shockstar Disco, Hormone Punk',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 0.95,
    artifact_7_round: 3.0,
    artifact_8: 'Inferno Metal, Shockstar Disco, Woodpecker Electro',
    artifact_8_1: 'Inferno Metal',
    artifact_8_2: 'Shockstar Disco',
    artifact_8_3: 'Woodpecker Electro',
    artifact_8_app: 0.95,
    artifact_8_round: 2.5,
    artifact_9: 'Woodpecker Electro, Inferno Metal',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Inferno Metal',
    artifact_9_3: '',
    artifact_9_app: 0.47,
    artifact_9_round: 2.0,
    artifact_10: 'Shockstar Disco, Swing Jazz, Flex',
    artifact_10_1: 'Shockstar Disco',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: 'Flex',
    artifact_10_app: 0.47,
    artifact_10_round: 3.0,
    app_0: 62.09,
    round_0: 2.79,
    app_1: 25.12,
    round_1: 2.76,
    app_2: 9.95,
    round_2: 2.95,
    app_3: 2.84,
    round_3: 2.8,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.54,
    sample: 211,
    sample_app_flat: 187
  },
  {
    char: 'Piper',
    app_rate: 10.0,
    avg_round: 2.79,
    std_dev_round: 0.44,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -10.01,
    diff_rounds: -0.04,
    weapon_1: 'Roaring Ride',
    weapon_1_app: 77.32,
    weapon_1_round: 2.79,
    weapon_2: 'Rainforest Gourmet',
    weapon_2_app: 7.1,
    weapon_2_round: 2.65,
    weapon_3: 'Fusion Compiler',
    weapon_3_app: 6.83,
    weapon_3_round: 2.91,
    weapon_4: 'Weeping Gemini',
    weapon_4_app: 5.46,
    weapon_4_round: 2.84,
    weapon_5: 'Electro-Lip Gloss',
    weapon_5_app: 1.37,
    weapon_5_round: 3.0,
    weapon_6: 'Sharpened Stinger',
    weapon_6_app: 0.82,
    weapon_6_round: 2.67,
    weapon_7: 'Flamemaker Shaker',
    weapon_7_app: 0.55,
    weapon_7_round: 3.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Freedom Blues',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Freedom Blues',
    artifact_1_3: '',
    artifact_1_app: 33.61,
    artifact_1_round: 2.79,
    artifact_2: 'Freedom Blues, Fanged Metal',
    artifact_2_1: 'Freedom Blues',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 23.77,
    artifact_2_round: 2.75,
    artifact_3: 'Freedom Blues, Swing Jazz',
    artifact_3_1: 'Freedom Blues',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 16.94,
    artifact_3_round: 2.81,
    artifact_4: 'Fanged Metal, Chaos Jazz',
    artifact_4_1: 'Fanged Metal',
    artifact_4_2: 'Chaos Jazz',
    artifact_4_3: '',
    artifact_4_app: 5.74,
    artifact_4_round: 2.94,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 5.46,
    artifact_5_round: 2.79,
    artifact_6: 'Fanged Metal, Flex',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 1.64,
    artifact_6_round: 2.67,
    artifact_7: 'Freedom Blues, Hormone Punk',
    artifact_7_1: 'Freedom Blues',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 1.64,
    artifact_7_round: 3.0,
    artifact_8: 'Fanged Metal, Hormone Punk',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: '',
    artifact_8_app: 1.37,
    artifact_8_round: 3.0,
    artifact_9: 'AP +30, AP +30, Fanged Metal',
    artifact_9_1: 'AP +30',
    artifact_9_2: 'AP +30',
    artifact_9_3: 'Fanged Metal',
    artifact_9_app: 1.37,
    artifact_9_round: 2.75,
    artifact_10: 'Fanged Metal, Swing Jazz',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 1.09,
    artifact_10_round: 3.0,
    app_0: 2.46,
    round_0: 2.5,
    app_1: 6.28,
    round_1: 2.78,
    app_2: 10.66,
    round_2: 2.63,
    app_3: 10.38,
    round_3: 2.71,
    app_4: 11.75,
    round_4: 2.86,
    app_5: 8.74,
    round_5: 2.88,
    app_6: 49.73,
    round_6: 2.83,
    cons_avg: 4.48,
    sample: 366,
    sample_app_flat: 325
  },
  {
    char: 'anby-demara',
    app_rate: 12.98,
    avg_round: 2.77,
    std_dev_round: 0.51,
    role: 'Stun',
    rarity: 'A',
    diff: 1.85,
    diff_rounds: 0.06,
    weapon_1: 'Demara Battery Mark II',
    weapon_1_app: 60.63,
    weapon_1_round: 2.72,
    weapon_2: 'Steam Oven',
    weapon_2_app: 13.89,
    weapon_2_round: 2.88,
    weapon_3: 'Hellfire Gears',
    weapon_3_app: 10.53,
    weapon_3_round: 2.87,
    weapon_4: 'The Restrained',
    weapon_4_app: 8.42,
    weapon_4_round: 2.85,
    weapon_5: 'Precious Fossilized Core',
    weapon_5_app: 3.16,
    weapon_5_round: 2.85,
    weapon_6: 'Six Shooter',
    weapon_6_app: 0.63,
    weapon_6_round: 2.67,
    weapon_7: 'Ice-Jade Teapot',
    weapon_7_app: 0.42,
    weapon_7_round: 3.0,
    weapon_8: '[Vortex] Arrow',
    weapon_8_app: 0.42,
    weapon_8_round: 3.0,
    weapon_9: 'Original Transmorpher',
    weapon_9_app: 0.42,
    weapon_9_round: 3.0,
    weapon_10: 'Blazing Laurel',
    weapon_10_app: 0.21,
    weapon_10_round: 1.0,
    artifact_1: 'Shockstar Disco, Swing Jazz',
    artifact_1_1: 'Shockstar Disco',
    artifact_1_2: 'Swing Jazz',
    artifact_1_3: '',
    artifact_1_app: 53.89,
    artifact_1_round: 2.83,
    artifact_2: 'Shockstar Disco, Thunder Metal',
    artifact_2_1: 'Shockstar Disco',
    artifact_2_2: 'Thunder Metal',
    artifact_2_3: '',
    artifact_2_app: 23.79,
    artifact_2_round: 2.67,
    artifact_3: 'Shockstar Disco, Woodpecker Electro',
    artifact_3_1: 'Shockstar Disco',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 8.0,
    artifact_3_round: 2.7,
    artifact_4: 'Shockstar Disco, Flex',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Flex',
    artifact_4_3: '',
    artifact_4_app: 3.16,
    artifact_4_round: 2.8,
    artifact_5: 'Shockstar Disco, Freedom Blues',
    artifact_5_1: 'Shockstar Disco',
    artifact_5_2: 'Freedom Blues',
    artifact_5_3: '',
    artifact_5_app: 2.11,
    artifact_5_round: 3.0,
    artifact_6: 'Shockstar Disco, Hormone Punk',
    artifact_6_1: 'Shockstar Disco',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 2.11,
    artifact_6_round: 3.0,
    artifact_7: 'Shockstar Disco, Thunder Metal, Flex',
    artifact_7_1: 'Shockstar Disco',
    artifact_7_2: 'Thunder Metal',
    artifact_7_3: 'Flex',
    artifact_7_app: 1.05,
    artifact_7_round: 3.0,
    artifact_8: 'Shockstar Disco, Puffer Electro',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Puffer Electro',
    artifact_8_3: '',
    artifact_8_app: 0.63,
    artifact_8_round: 3.0,
    artifact_9: 'Thunder Metal, Flex',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Flex',
    artifact_9_3: '',
    artifact_9_app: 0.42,
    artifact_9_round: 2.0,
    artifact_10: 'Proto Punk, Shockstar Disco',
    artifact_10_1: 'Proto Punk',
    artifact_10_2: 'Shockstar Disco',
    artifact_10_3: '',
    artifact_10_app: 0.42,
    artifact_10_round: 3.0,
    app_0: 1.68,
    round_0: 2.5,
    app_1: 1.47,
    round_1: 2.33,
    app_2: 3.58,
    round_2: 2.44,
    app_3: 5.89,
    round_3: 2.93,
    app_4: 3.79,
    round_4: 2.71,
    app_5: 6.32,
    round_5: 2.83,
    app_6: 77.26,
    round_6: 2.79,
    cons_avg: 5.37,
    sample: 475,
    sample_app_flat: 420
  },
  {
    char: 'soldier-11',
    app_rate: 8.28,
    avg_round: 2.77,
    std_dev_round: 0.48,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -2.26,
    diff_rounds: -0.02,
    weapon_1: 'The Brimstone',
    weapon_1_app: 41.58,
    weapon_1_round: 2.85,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 35.64,
    weapon_2_round: 2.73,
    weapon_3: 'Cannon Rotor',
    weapon_3_app: 8.58,
    weapon_3_round: 2.6,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 8.25,
    weapon_4_round: 2.71,
    weapon_5: 'Steel Cushion',
    weapon_5_app: 2.64,
    weapon_5_round: 2.88,
    weapon_6: 'Street Superstar',
    weapon_6_app: 2.31,
    weapon_6_round: 2.86,
    weapon_7: 'Housekeeper',
    weapon_7_app: 0.66,
    weapon_7_round: 2.5,
    weapon_8: '[Lunar] Pleniluna',
    weapon_8_app: 0.33,
    weapon_8_round: 3.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Inferno Metal, Woodpecker Electro',
    artifact_1_1: 'Inferno Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 72.61,
    artifact_1_round: 2.75,
    artifact_2: 'Inferno Metal, Puffer Electro',
    artifact_2_1: 'Inferno Metal',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 11.88,
    artifact_2_round: 2.93,
    artifact_3: 'Inferno Metal, Flex',
    artifact_3_1: 'Inferno Metal',
    artifact_3_2: 'Flex',
    artifact_3_3: '',
    artifact_3_app: 3.3,
    artifact_3_round: 2.89,
    artifact_4: 'Woodpecker Electro, Inferno Metal',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Inferno Metal',
    artifact_4_3: '',
    artifact_4_app: 2.97,
    artifact_4_round: 2.83,
    artifact_5: 'Inferno Metal, Swing Jazz',
    artifact_5_1: 'Inferno Metal',
    artifact_5_2: 'Swing Jazz',
    artifact_5_3: '',
    artifact_5_app: 2.64,
    artifact_5_round: 2.75,
    artifact_6: 'Inferno Metal, Hormone Punk',
    artifact_6_1: 'Inferno Metal',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 2.31,
    artifact_6_round: 2.83,
    artifact_7: 'Woodpecker Electro, Hormone Punk',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Hormone Punk',
    artifact_7_3: '',
    artifact_7_app: 0.99,
    artifact_7_round: 2.33,
    artifact_8: 'Puffer Electro, Inferno Metal',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Inferno Metal',
    artifact_8_3: '',
    artifact_8_app: 0.66,
    artifact_8_round: 3.0,
    artifact_9: 'Hormone Punk, Inferno Metal',
    artifact_9_1: 'Hormone Punk',
    artifact_9_2: 'Inferno Metal',
    artifact_9_3: '',
    artifact_9_app: 0.33,
    artifact_9_round: 3.0,
    artifact_10: 'Woodpecker Electro, Puffer Electro',
    artifact_10_1: 'Woodpecker Electro',
    artifact_10_2: 'Puffer Electro',
    artifact_10_3: '',
    artifact_10_app: 0.33,
    artifact_10_round: 1.0,
    app_0: 58.75,
    round_0: 2.75,
    app_1: 31.35,
    round_1: 2.79,
    app_2: 7.59,
    round_2: 2.83,
    app_3: 2.31,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.53,
    sample: 303,
    sample_app_flat: 267
  },
  {
    char: 'billy-kid',
    app_rate: 1.23,
    avg_round: 2.73,
    std_dev_round: 0.55,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.51,
    diff_rounds: 0.07,
    weapon_1: 'Starlight Engine Replica',
    weapon_1_app: 57.78,
    weapon_1_round: 2.7,
    weapon_2: 'The Brimstone',
    weapon_2_app: 13.33,
    weapon_2_round: 3.0,
    weapon_3: 'Steel Cushion',
    weapon_3_app: 11.11,
    weapon_3_round: 2.5,
    weapon_4: 'Starlight Engine',
    weapon_4_app: 6.67,
    weapon_4_round: 2.33,
    weapon_5: 'Street Superstar',
    weapon_5_app: 6.67,
    weapon_5_round: 3.0,
    weapon_6: '',
    weapon_6_app: 0.0,
    weapon_6_round: 1.0,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 1.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Woodpecker Electro, Fanged Metal',
    artifact_1_1: 'Woodpecker Electro',
    artifact_1_2: 'Fanged Metal',
    artifact_1_3: '',
    artifact_1_app: 33.33,
    artifact_1_round: 2.8,
    artifact_2: 'Woodpecker Electro, Puffer Electro',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Puffer Electro',
    artifact_2_3: '',
    artifact_2_app: 13.33,
    artifact_2_round: 3.0,
    artifact_3: 'Fanged Metal, Woodpecker Electro',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 13.33,
    artifact_3_round: 2.83,
    artifact_4: 'Woodpecker Electro, Hormone Punk',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 11.11,
    artifact_4_round: 2.4,
    artifact_5: 'Puffer Electro, Woodpecker Electro',
    artifact_5_1: 'Puffer Electro',
    artifact_5_2: 'Woodpecker Electro',
    artifact_5_3: '',
    artifact_5_app: 11.11,
    artifact_5_round: 2.75,
    artifact_6: 'Hormone Punk, Woodpecker Electro',
    artifact_6_1: 'Hormone Punk',
    artifact_6_2: 'Woodpecker Electro',
    artifact_6_3: '',
    artifact_6_app: 4.44,
    artifact_6_round: 3.0,
    artifact_7: 'Puffer Electro, Woodpecker Electro, Flex',
    artifact_7_1: 'Puffer Electro',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: 'Flex',
    artifact_7_app: 2.22,
    artifact_7_round: 3.0,
    artifact_8: 'Puffer Electro, Fanged Metal',
    artifact_8_1: 'Puffer Electro',
    artifact_8_2: 'Fanged Metal',
    artifact_8_3: '',
    artifact_8_app: 2.22,
    artifact_8_round: 2.0,
    artifact_9: 'Woodpecker Electro, Shockstar Disco',
    artifact_9_1: 'Woodpecker Electro',
    artifact_9_2: 'Shockstar Disco',
    artifact_9_3: '',
    artifact_9_app: 2.22,
    artifact_9_round: 1.0,
    artifact_10: 'Fanged Metal, Hormone Punk',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Hormone Punk',
    artifact_10_3: '',
    artifact_10_app: 2.22,
    artifact_10_round: 3.0,
    app_0: 2.22,
    round_0: 3.0,
    app_1: 2.22,
    round_1: 2.0,
    app_2: 2.22,
    round_2: 3.0,
    app_3: 0.0,
    round_3: 1.0,
    app_4: 2.22,
    round_4: 1.0,
    app_5: 13.33,
    round_5: 2.83,
    app_6: 77.78,
    round_6: 2.77,
    cons_avg: 5.49,
    sample: 45,
    sample_app_flat: 41
  },
  {
    char: 'grace-howard',
    app_rate: 6.78,
    avg_round: 2.72,
    std_dev_round: 0.51,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -1.32,
    diff_rounds: 0.0,
    weapon_1: 'Fusion Compiler',
    weapon_1_app: 33.87,
    weapon_1_round: 2.74,
    weapon_2: 'Weeping Gemini',
    weapon_2_app: 27.02,
    weapon_2_round: 2.66,
    weapon_3: 'Rainforest Gourmet',
    weapon_3_app: 21.77,
    weapon_3_round: 2.68,
    weapon_4: 'Electro-Lip Gloss',
    weapon_4_app: 8.47,
    weapon_4_round: 2.94,
    weapon_5: 'Roaring Ride',
    weapon_5_app: 2.82,
    weapon_5_round: 2.6,
    weapon_6: 'Timeweaver',
    weapon_6_app: 1.61,
    weapon_6_round: 2.75,
    weapon_7: '[Magnetic Storm] Alpha',
    weapon_7_app: 0.81,
    weapon_7_round: 3.0,
    weapon_8: '[Magnetic Storm] Bravo',
    weapon_8_app: 0.81,
    weapon_8_round: 2.5,
    weapon_9: 'Steam Oven',
    weapon_9_app: 0.4,
    weapon_9_round: 2.0,
    weapon_10: 'Sharpened Stinger',
    weapon_10_app: 0.4,
    weapon_10_round: 3.0,
    artifact_1: 'Freedom Blues, Thunder Metal',
    artifact_1_1: 'Freedom Blues',
    artifact_1_2: 'Thunder Metal',
    artifact_1_3: '',
    artifact_1_app: 29.84,
    artifact_1_round: 2.67,
    artifact_2: 'Thunder Metal, Freedom Blues',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Freedom Blues',
    artifact_2_3: '',
    artifact_2_app: 23.39,
    artifact_2_round: 2.73,
    artifact_3: 'Chaos Jazz, Freedom Blues',
    artifact_3_1: 'Chaos Jazz',
    artifact_3_2: 'Freedom Blues',
    artifact_3_3: '',
    artifact_3_app: 9.68,
    artifact_3_round: 2.67,
    artifact_4: 'Freedom Blues, Swing Jazz',
    artifact_4_1: 'Freedom Blues',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 6.45,
    artifact_4_round: 2.64,
    artifact_5: 'Freedom Blues, Chaos Jazz',
    artifact_5_1: 'Freedom Blues',
    artifact_5_2: 'Chaos Jazz',
    artifact_5_3: '',
    artifact_5_app: 6.05,
    artifact_5_round: 2.92,
    artifact_6: 'Chaos Jazz, Thunder Metal',
    artifact_6_1: 'Chaos Jazz',
    artifact_6_2: 'Thunder Metal',
    artifact_6_3: '',
    artifact_6_app: 2.82,
    artifact_6_round: 2.83,
    artifact_7: 'AP +30, AP +30, Thunder Metal',
    artifact_7_1: 'AP +30',
    artifact_7_2: 'AP +30',
    artifact_7_3: 'Thunder Metal',
    artifact_7_app: 2.42,
    artifact_7_round: 2.8,
    artifact_8: 'Freedom Blues, Puffer Electro',
    artifact_8_1: 'Freedom Blues',
    artifact_8_2: 'Puffer Electro',
    artifact_8_3: '',
    artifact_8_app: 1.61,
    artifact_8_round: 3.0,
    artifact_9: 'Thunder Metal, Chaos Jazz',
    artifact_9_1: 'Thunder Metal',
    artifact_9_2: 'Chaos Jazz',
    artifact_9_3: '',
    artifact_9_app: 1.61,
    artifact_9_round: 2.75,
    artifact_10: 'Thunder Metal, Swing Jazz',
    artifact_10_1: 'Thunder Metal',
    artifact_10_2: 'Swing Jazz',
    artifact_10_3: '',
    artifact_10_app: 1.21,
    artifact_10_round: 3.0,
    app_0: 62.1,
    round_0: 2.72,
    app_1: 27.42,
    round_1: 2.78,
    app_2: 7.66,
    round_2: 2.4,
    app_3: 2.82,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 0.0,
    round_5: 1.0,
    app_6: 0.0,
    round_6: 1.0,
    cons_avg: 0.51,
    sample: 248,
    sample_app_flat: 219
  },
  {
    char: 'Nekomata',
    app_rate: 2.6,
    avg_round: 2.7,
    std_dev_round: 0.56,
    role: 'Damage Dealer',
    rarity: 'Standard S',
    diff: -1.2,
    diff_rounds: -0.02,
    weapon_1: 'Steel Cushion',
    weapon_1_app: 49.47,
    weapon_1_round: 2.86,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 22.11,
    weapon_2_round: 2.73,
    weapon_3: 'The Brimstone',
    weapon_3_app: 11.58,
    weapon_3_round: 2.27,
    weapon_4: 'Gilded Blossom',
    weapon_4_app: 7.37,
    weapon_4_round: 2.43,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 4.21,
    weapon_5_round: 3.0,
    weapon_6: 'Street Superstar',
    weapon_6_app: 2.11,
    weapon_6_round: 2.0,
    weapon_7: 'Starlight Engine Replica',
    weapon_7_app: 1.05,
    weapon_7_round: 3.0,
    weapon_8: 'Deep Sea Visitor',
    weapon_8_app: 1.05,
    weapon_8_round: 2.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 43.16,
    artifact_1_round: 2.66,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 25.26,
    artifact_2_round: 2.74,
    artifact_3: 'Fanged Metal, Hormone Punk',
    artifact_3_1: 'Fanged Metal',
    artifact_3_2: 'Hormone Punk',
    artifact_3_3: '',
    artifact_3_app: 9.47,
    artifact_3_round: 2.5,
    artifact_4: 'Woodpecker Electro, Hormone Punk',
    artifact_4_1: 'Woodpecker Electro',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 4.21,
    artifact_4_round: 2.75,
    artifact_5: 'Hormone Punk, Fanged Metal',
    artifact_5_1: 'Hormone Punk',
    artifact_5_2: 'Fanged Metal',
    artifact_5_3: '',
    artifact_5_app: 3.16,
    artifact_5_round: 2.67,
    artifact_6: 'Woodpecker Electro, Flex',
    artifact_6_1: 'Woodpecker Electro',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 3.16,
    artifact_6_round: 2.5,
    artifact_7: 'Fanged Metal, Puffer Electro',
    artifact_7_1: 'Fanged Metal',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 1.05,
    artifact_7_round: 3.0,
    artifact_8: 'Woodpecker Electro, Puffer Electro',
    artifact_8_1: 'Woodpecker Electro',
    artifact_8_2: 'Puffer Electro',
    artifact_8_3: '',
    artifact_8_app: 1.05,
    artifact_8_round: 3.0,
    artifact_9: 'Fanged Metal, Woodpecker Electro, Swing Jazz',
    artifact_9_1: 'Fanged Metal',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: 'Swing Jazz',
    artifact_9_app: 1.05,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Woodpecker Electro, Hormone Punk',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: 'Hormone Punk',
    artifact_10_app: 1.05,
    artifact_10_round: 3.0,
    app_0: 53.68,
    round_0: 2.61,
    app_1: 35.79,
    round_1: 2.82,
    app_2: 6.32,
    round_2: 2.67,
    app_3: 2.11,
    round_3: 3.0,
    app_4: 0.0,
    round_4: 1.0,
    app_5: 1.05,
    round_5: 3.0,
    app_6: 1.05,
    round_6: 1.0,
    cons_avg: 0.66,
    sample: 95,
    sample_app_flat: 83
  },
  {
    char: 'Anton',
    app_rate: 3.22,
    avg_round: 2.69,
    std_dev_round: 0.6,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.16,
    diff_rounds: 0.01,
    weapon_1: 'Drill Rig - Red Axis',
    weapon_1_app: 72.03,
    weapon_1_round: 2.67,
    weapon_2: 'Starlight Engine',
    weapon_2_app: 9.32,
    weapon_2_round: 3.0,
    weapon_3: 'The Brimstone',
    weapon_3_app: 7.63,
    weapon_3_round: 2.57,
    weapon_4: 'Street Superstar',
    weapon_4_app: 5.93,
    weapon_4_round: 2.71,
    weapon_5: 'Cannon Rotor',
    weapon_5_app: 2.54,
    weapon_5_round: 3.0,
    weapon_6: 'Gilded Blossom',
    weapon_6_app: 1.69,
    weapon_6_round: 2.5,
    weapon_7: 'Steel Cushion',
    weapon_7_app: 0.85,
    weapon_7_round: 1.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Thunder Metal, Woodpecker Electro',
    artifact_1_1: 'Thunder Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 72.03,
    artifact_1_round: 2.74,
    artifact_2: 'Thunder Metal, Swing Jazz',
    artifact_2_1: 'Thunder Metal',
    artifact_2_2: 'Swing Jazz',
    artifact_2_3: '',
    artifact_2_app: 6.78,
    artifact_2_round: 2.25,
    artifact_3: 'Woodpecker Electro, Thunder Metal',
    artifact_3_1: 'Woodpecker Electro',
    artifact_3_2: 'Thunder Metal',
    artifact_3_3: '',
    artifact_3_app: 4.24,
    artifact_3_round: 2.5,
    artifact_4: 'Thunder Metal, Hormone Punk',
    artifact_4_1: 'Thunder Metal',
    artifact_4_2: 'Hormone Punk',
    artifact_4_3: '',
    artifact_4_app: 4.24,
    artifact_4_round: 2.6,
    artifact_5: 'Thunder Metal, Flex',
    artifact_5_1: 'Thunder Metal',
    artifact_5_2: 'Flex',
    artifact_5_3: '',
    artifact_5_app: 3.39,
    artifact_5_round: 3.0,
    artifact_6: 'Woodpecker Electro, Flex',
    artifact_6_1: 'Woodpecker Electro',
    artifact_6_2: 'Flex',
    artifact_6_3: '',
    artifact_6_app: 1.69,
    artifact_6_round: 2.5,
    artifact_7: 'Thunder Metal, Woodpecker Electro, Flex',
    artifact_7_1: 'Thunder Metal',
    artifact_7_2: 'Woodpecker Electro',
    artifact_7_3: 'Flex',
    artifact_7_app: 0.85,
    artifact_7_round: 3.0,
    artifact_8: 'Swing Jazz, Thunder Metal, Woodpecker Electro',
    artifact_8_1: 'Swing Jazz',
    artifact_8_2: 'Thunder Metal',
    artifact_8_3: 'Woodpecker Electro',
    artifact_8_app: 0.85,
    artifact_8_round: 1.0,
    artifact_9: 'Puffer Electro, Thunder Metal',
    artifact_9_1: 'Puffer Electro',
    artifact_9_2: 'Thunder Metal',
    artifact_9_3: '',
    artifact_9_app: 0.85,
    artifact_9_round: 3.0,
    artifact_10: 'Hormone Punk, Woodpecker Electro, Thunder Metal',
    artifact_10_1: 'Hormone Punk',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: 'Thunder Metal',
    artifact_10_app: 0.85,
    artifact_10_round: 2.0,
    app_0: 1.69,
    round_0: 2.0,
    app_1: 4.24,
    round_1: 1.8,
    app_2: 0.85,
    round_2: 3.0,
    app_3: 2.54,
    round_3: 3.0,
    app_4: 6.78,
    round_4: 2.62,
    app_5: 5.93,
    round_5: 2.83,
    app_6: 77.97,
    round_6: 2.74,
    cons_avg: 5.38,
    sample: 118,
    sample_app_flat: 111
  },
  {
    char: 'Corin',
    app_rate: 2.21,
    avg_round: 2.68,
    std_dev_round: 0.58,
    role: 'Damage Dealer',
    rarity: 'A',
    diff: -0.75,
    diff_rounds: 0.01,
    weapon_1: 'Housekeeper',
    weapon_1_app: 62.96,
    weapon_1_round: 2.67,
    weapon_2: 'Steel Cushion',
    weapon_2_app: 17.28,
    weapon_2_round: 2.73,
    weapon_3: 'Gilded Blossom',
    weapon_3_app: 8.64,
    weapon_3_round: 2.57,
    weapon_4: 'The Brimstone',
    weapon_4_app: 3.7,
    weapon_4_round: 2.33,
    weapon_5: 'Street Superstar',
    weapon_5_app: 1.23,
    weapon_5_round: 3.0,
    weapon_6: 'Starlight Engine',
    weapon_6_app: 1.23,
    weapon_6_round: 3.0,
    weapon_7: 'Cannon Rotor',
    weapon_7_app: 1.23,
    weapon_7_round: 3.0,
    weapon_8: '[Lunar] Noviluna',
    weapon_8_app: 1.23,
    weapon_8_round: 3.0,
    weapon_9: 'Deep Sea Visitor',
    weapon_9_app: 1.23,
    weapon_9_round: 3.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Fanged Metal, Woodpecker Electro',
    artifact_1_1: 'Fanged Metal',
    artifact_1_2: 'Woodpecker Electro',
    artifact_1_3: '',
    artifact_1_app: 40.74,
    artifact_1_round: 2.52,
    artifact_2: 'Woodpecker Electro, Fanged Metal',
    artifact_2_1: 'Woodpecker Electro',
    artifact_2_2: 'Fanged Metal',
    artifact_2_3: '',
    artifact_2_app: 11.11,
    artifact_2_round: 2.86,
    artifact_3: 'Hormone Punk, Woodpecker Electro',
    artifact_3_1: 'Hormone Punk',
    artifact_3_2: 'Woodpecker Electro',
    artifact_3_3: '',
    artifact_3_app: 9.88,
    artifact_3_round: 3.0,
    artifact_4: 'Hormone Punk, Fanged Metal',
    artifact_4_1: 'Hormone Punk',
    artifact_4_2: 'Fanged Metal',
    artifact_4_3: '',
    artifact_4_app: 8.64,
    artifact_4_round: 2.83,
    artifact_5: 'Puffer Electro, Woodpecker Electro',
    artifact_5_1: 'Puffer Electro',
    artifact_5_2: 'Woodpecker Electro',
    artifact_5_3: '',
    artifact_5_app: 6.17,
    artifact_5_round: 2.5,
    artifact_6: 'Fanged Metal, Hormone Punk',
    artifact_6_1: 'Fanged Metal',
    artifact_6_2: 'Hormone Punk',
    artifact_6_3: '',
    artifact_6_app: 4.94,
    artifact_6_round: 3.0,
    artifact_7: 'Woodpecker Electro, Puffer Electro',
    artifact_7_1: 'Woodpecker Electro',
    artifact_7_2: 'Puffer Electro',
    artifact_7_3: '',
    artifact_7_app: 2.47,
    artifact_7_round: 3.0,
    artifact_8: 'Fanged Metal, Hormone Punk, Woodpecker Electro',
    artifact_8_1: 'Fanged Metal',
    artifact_8_2: 'Hormone Punk',
    artifact_8_3: 'Woodpecker Electro',
    artifact_8_app: 2.47,
    artifact_8_round: 2.5,
    artifact_9: 'Fanged Metal, Puffer Electro',
    artifact_9_1: 'Fanged Metal',
    artifact_9_2: 'Puffer Electro',
    artifact_9_3: '',
    artifact_9_app: 1.23,
    artifact_9_round: 3.0,
    artifact_10: 'Fanged Metal, Woodpecker Electro, Hormone Punk',
    artifact_10_1: 'Fanged Metal',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: 'Hormone Punk',
    artifact_10_app: 1.23,
    artifact_10_round: 2.0,
    app_0: 4.94,
    round_0: 1.75,
    app_1: 1.23,
    round_1: 2.0,
    app_2: 3.7,
    round_2: 2.67,
    app_3: 3.7,
    round_3: 2.67,
    app_4: 6.17,
    round_4: 2.6,
    app_5: 7.41,
    round_5: 3.0,
    app_6: 72.84,
    round_6: 2.74,
    cons_avg: 5.19,
    sample: 81,
    sample_app_flat: 69
  },
  {
    char: 'Ben',
    app_rate: 1.04,
    avg_round: 2.57,
    std_dev_round: 0.56,
    role: 'Stun',
    rarity: 'A',
    diff: -0.46,
    diff_rounds: 0.06,
    weapon_1: 'Big Cylinder',
    weapon_1_app: 73.68,
    weapon_1_round: 2.6,
    weapon_2: 'Bunny Band',
    weapon_2_app: 10.53,
    weapon_2_round: 2.25,
    weapon_3: 'Original Transmorpher',
    weapon_3_app: 7.89,
    weapon_3_round: 2.33,
    weapon_4: 'Tusks of Fury',
    weapon_4_app: 5.26,
    weapon_4_round: 3.0,
    weapon_5: 'Hellfire Gears',
    weapon_5_app: 2.63,
    weapon_5_round: 3.0,
    weapon_6: '',
    weapon_6_app: 0.0,
    weapon_6_round: 1.0,
    weapon_7: '',
    weapon_7_app: 0.0,
    weapon_7_round: 1.0,
    weapon_8: '',
    weapon_8_app: 0.0,
    weapon_8_round: 1.0,
    weapon_9: '',
    weapon_9_app: 0.0,
    weapon_9_round: 1.0,
    weapon_10: '',
    weapon_10_app: 0.0,
    weapon_10_round: 1.0,
    artifact_1: 'Swing Jazz, Soul Rock',
    artifact_1_1: 'Swing Jazz',
    artifact_1_2: 'Soul Rock',
    artifact_1_3: '',
    artifact_1_app: 21.05,
    artifact_1_round: 2.38,
    artifact_2: 'Proto Punk, Soul Rock',
    artifact_2_1: 'Proto Punk',
    artifact_2_2: 'Soul Rock',
    artifact_2_3: '',
    artifact_2_app: 10.53,
    artifact_2_round: 2.25,
    artifact_3: 'Soul Rock, Swing Jazz',
    artifact_3_1: 'Soul Rock',
    artifact_3_2: 'Swing Jazz',
    artifact_3_3: '',
    artifact_3_app: 10.53,
    artifact_3_round: 2.33,
    artifact_4: 'Shockstar Disco, Swing Jazz',
    artifact_4_1: 'Shockstar Disco',
    artifact_4_2: 'Swing Jazz',
    artifact_4_3: '',
    artifact_4_app: 7.89,
    artifact_4_round: 2.67,
    artifact_5: 'Swing Jazz, Shockstar Disco',
    artifact_5_1: 'Swing Jazz',
    artifact_5_2: 'Shockstar Disco',
    artifact_5_3: '',
    artifact_5_app: 7.89,
    artifact_5_round: 3.0,
    artifact_6: 'Inferno Metal, Soul Rock',
    artifact_6_1: 'Inferno Metal',
    artifact_6_2: 'Soul Rock',
    artifact_6_3: '',
    artifact_6_app: 5.26,
    artifact_6_round: 2.0,
    artifact_7: 'Proto Punk, Shockstar Disco',
    artifact_7_1: 'Proto Punk',
    artifact_7_2: 'Shockstar Disco',
    artifact_7_3: '',
    artifact_7_app: 5.26,
    artifact_7_round: 3.0,
    artifact_8: 'Shockstar Disco, Soul Rock, Swing Jazz',
    artifact_8_1: 'Shockstar Disco',
    artifact_8_2: 'Soul Rock',
    artifact_8_3: 'Swing Jazz',
    artifact_8_app: 5.26,
    artifact_8_round: 3.0,
    artifact_9: 'Proto Punk, Woodpecker Electro',
    artifact_9_1: 'Proto Punk',
    artifact_9_2: 'Woodpecker Electro',
    artifact_9_3: '',
    artifact_9_app: 2.63,
    artifact_9_round: 1.0,
    artifact_10: 'Inferno Metal, Woodpecker Electro',
    artifact_10_1: 'Inferno Metal',
    artifact_10_2: 'Woodpecker Electro',
    artifact_10_3: '',
    artifact_10_app: 2.63,
    artifact_10_round: 3.0,
    app_0: 2.63,
    round_0: 1.0,
    app_1: 2.63,
    round_1: 3.0,
    app_2: 7.89,
    round_2: 2.0,
    app_3: 10.53,
    round_3: 2.75,
    app_4: 13.16,
    round_4: 2.4,
    app_5: 5.26,
    round_5: 2.5,
    app_6: 57.89,
    round_6: 2.7,
    cons_avg: 4.76,
    sample: 38,
    sample_app_flat: 35
  }
];
