export const ShiyuDuos = [
  {
    char: 'Ben',
    round: 2.57,
    char_1: 'soldier-11',
    app_rate_1: 55.26,
    avg_round_1: 2.67,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 52.63,
    avg_round_2: 2.59,
    app_flat_2: 0,
    char_3: 'Koleda',
    app_rate_3: 21.05,
    avg_round_3: 2.5,
    app_flat_3: 0,
    char_4: 'Burnice',
    app_rate_4: 10.53,
    avg_round_4: 2.67,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 10.53,
    avg_round_5: 2.75,
    app_flat_5: 0,
    char_6: 'Lighter',
    app_rate_6: 10.53,
    avg_round_6: 2.75,
    app_flat_6: 0,
    char_7: 'Piper',
    app_rate_7: 10.53,
    avg_round_7: 2.5,
    app_flat_7: 0,
    char_8: 'Corin',
    app_rate_8: 2.63,
    avg_round_8: 1,
    app_flat_8: 0,
    char_9: 'Rina',
    app_rate_9: 2.63,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'Caesar',
    app_rate_10: 2.63,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Qingyi',
    app_rate_11: 2.63,
    avg_round_11: 1,
    app_flat_11: 0,
    char_12: 'Ellen',
    app_rate_12: 2.63,
    avg_round_12: 2,
    app_flat_12: 0,
    char_13: 'Soukaku',
    app_rate_13: 2.63,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Anton',
    app_rate_14: 2.63,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: 'zhu-yuan',
    app_rate_15: 2.63,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'nicole-demara',
    app_rate_16: 2.63,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'grace-howard',
    app_rate_17: 2.63,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'jane-doe',
    app_rate_18: 2.63,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Corin',
    round: 2.68,
    char_1: 'Qingyi',
    app_rate_1: 39.51,
    avg_round_1: 2.77,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 34.57,
    avg_round_2: 2.72,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 29.63,
    avg_round_3: 2.94,
    app_flat_3: 0,
    char_4: 'Lucy',
    app_rate_4: 27.16,
    avg_round_4: 2.65,
    app_flat_4: 0,
    char_5: 'Lycaon',
    app_rate_5: 17.28,
    avg_round_5: 2.71,
    app_flat_5: 0,
    char_6: 'Soukaku',
    app_rate_6: 16.05,
    avg_round_6: 2.38,
    app_flat_6: 0,
    char_7: 'Piper',
    app_rate_7: 11.11,
    avg_round_7: 2.56,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 9.88,
    avg_round_8: 2.71,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 3.7,
    avg_round_9: 2.67,
    app_flat_9: 0,
    char_10: 'Burnice',
    app_rate_10: 3.7,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Ben',
    app_rate_11: 1.23,
    avg_round_11: 1,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 1.23,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'Lighter',
    app_rate_13: 1.23,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Koleda',
    app_rate_14: 1.23,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 1.23,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Anton',
    round: 2.69,
    char_1: 'Qingyi',
    app_rate_1: 57.63,
    avg_round_1: 2.86,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 44.92,
    avg_round_2: 2.77,
    app_flat_2: 0,
    char_3: 'grace-howard',
    app_rate_3: 29.66,
    avg_round_3: 2.66,
    app_flat_3: 0,
    char_4: 'anby-demara',
    app_rate_4: 21.19,
    avg_round_4: 2.48,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 15.25,
    avg_round_5: 2.94,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 10.17,
    avg_round_6: 2.5,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 5.93,
    avg_round_7: 2,
    app_flat_7: 0,
    char_8: 'Lucy',
    app_rate_8: 3.39,
    avg_round_8: 2.5,
    app_flat_8: 0,
    char_9: 'Seth',
    app_rate_9: 3.39,
    avg_round_9: 2.67,
    app_flat_9: 0,
    char_10: 'Lighter',
    app_rate_10: 2.54,
    avg_round_10: 2.33,
    app_flat_10: 0,
    char_11: 'Yanagi',
    app_rate_11: 1.69,
    avg_round_11: 2,
    app_flat_11: 0,
    char_12: 'Burnice',
    app_rate_12: 1.69,
    avg_round_12: 1.5,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 0.85,
    avg_round_13: 1,
    app_flat_13: 0,
    char_14: 'billy-kid',
    app_rate_14: 0.85,
    avg_round_14: 1,
    app_flat_14: 0,
    char_15: 'Ben',
    app_rate_15: 0.85,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: '-',
    app_rate_16: 0.0,
    avg_round_16: 0.0,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Nekomata',
    round: 2.7,
    char_1: 'nicole-demara',
    app_rate_1: 48.42,
    avg_round_1: 2.77,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 38.95,
    avg_round_2: 2.78,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 31.58,
    avg_round_3: 2.82,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 27.37,
    avg_round_4: 2.63,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 24.21,
    avg_round_5: 2.64,
    app_flat_5: 0,
    char_6: 'anby-demara',
    app_rate_6: 8.42,
    avg_round_6: 2.5,
    app_flat_6: 0,
    char_7: 'Burnice',
    app_rate_7: 7.37,
    avg_round_7: 2.33,
    app_flat_7: 0,
    char_8: 'Soukaku',
    app_rate_8: 2.11,
    avg_round_8: 2.5,
    app_flat_8: 0,
    char_9: 'Koleda',
    app_rate_9: 2.11,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'billy-kid',
    app_rate_10: 2.11,
    avg_round_10: 2,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 1.05,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Rina',
    app_rate_12: 1.05,
    avg_round_12: 1,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 1.05,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'soldier-11',
    app_rate_14: 1.05,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Lighter',
    app_rate_15: 1.05,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'grace-howard',
    app_rate_16: 1.05,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'zhu-yuan',
    app_rate_17: 1.05,
    avg_round_17: 1,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'grace-howard',
    round: 2.72,
    char_1: 'Burnice',
    app_rate_1: 29.84,
    avg_round_1: 2.84,
    app_flat_1: 0,
    char_2: 'Seth',
    app_rate_2: 28.23,
    avg_round_2: 2.73,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 22.58,
    avg_round_3: 2.75,
    app_flat_3: 0,
    char_4: 'Yanagi',
    app_rate_4: 21.37,
    avg_round_4: 2.72,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 15.32,
    avg_round_5: 2.72,
    app_flat_5: 0,
    char_6: 'Anton',
    app_rate_6: 14.11,
    avg_round_6: 2.66,
    app_flat_6: 0,
    char_7: 'Lucy',
    app_rate_7: 13.71,
    avg_round_7: 2.78,
    app_flat_7: 0,
    char_8: 'Caesar',
    app_rate_8: 12.9,
    avg_round_8: 2.75,
    app_flat_8: 0,
    char_9: 'Qingyi',
    app_rate_9: 11.29,
    avg_round_9: 2.78,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 7.66,
    avg_round_10: 2.37,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 4.84,
    avg_round_11: 2.82,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 4.03,
    avg_round_12: 2.78,
    app_flat_12: 0,
    char_13: 'Piper',
    app_rate_13: 3.23,
    avg_round_13: 2.38,
    app_flat_13: 0,
    char_14: 'Ellen',
    app_rate_14: 2.82,
    avg_round_14: 2.71,
    app_flat_14: 0,
    char_15: 'Koleda',
    app_rate_15: 2.82,
    avg_round_15: 2.67,
    app_flat_15: 0,
    char_16: 'Lighter',
    app_rate_16: 2.02,
    avg_round_16: 2.4,
    app_flat_16: 0,
    char_17: 'zhu-yuan',
    app_rate_17: 0.81,
    avg_round_17: 1,
    app_flat_17: 0,
    char_18: 'Lycaon',
    app_rate_18: 0.4,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Nekomata',
    app_rate_19: 0.4,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: 'Ben',
    app_rate_20: 0.4,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'billy-kid',
    round: 2.73,
    char_1: 'nicole-demara',
    app_rate_1: 57.78,
    avg_round_1: 2.78,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 42.22,
    avg_round_2: 2.83,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 22.22,
    avg_round_3: 2.9,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 22.22,
    avg_round_4: 2.71,
    app_flat_4: 0,
    char_5: 'Lucy',
    app_rate_5: 15.56,
    avg_round_5: 3,
    app_flat_5: 0,
    char_6: 'Soukaku',
    app_rate_6: 6.67,
    avg_round_6: 2.67,
    app_flat_6: 0,
    char_7: 'Nekomata',
    app_rate_7: 4.44,
    avg_round_7: 2,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 2.22,
    avg_round_8: 3,
    app_flat_8: 0,
    char_9: 'Piper',
    app_rate_9: 2.22,
    avg_round_9: 2,
    app_flat_9: 0,
    char_10: 'soldier-11',
    app_rate_10: 2.22,
    avg_round_10: 3,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 2.22,
    avg_round_11: 2,
    app_flat_11: 0,
    char_12: 'Lighter',
    app_rate_12: 2.22,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 2.22,
    avg_round_13: 1,
    app_flat_13: 0,
    char_14: 'Anton',
    app_rate_14: 2.22,
    avg_round_14: 1,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 2.22,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: 'Rina',
    app_rate_16: 2.22,
    avg_round_16: 1,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'anby-demara',
    round: 2.77,
    char_1: 'nicole-demara',
    app_rate_1: 60.21,
    avg_round_1: 2.86,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 50.95,
    avg_round_2: 2.88,
    app_flat_2: 0,
    char_3: 'Yanagi',
    app_rate_3: 20.63,
    avg_round_3: 2.7,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 12.21,
    avg_round_4: 2.64,
    app_flat_4: 0,
    char_5: 'Ellen',
    app_rate_5: 11.37,
    avg_round_5: 2.71,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 9.47,
    avg_round_6: 2.67,
    app_flat_6: 0,
    char_7: 'Seth',
    app_rate_7: 8.42,
    avg_round_7: 2.64,
    app_flat_7: 0,
    char_8: 'Anton',
    app_rate_8: 5.26,
    avg_round_8: 2.48,
    app_flat_8: 0,
    char_9: 'grace-howard',
    app_rate_9: 4.0,
    avg_round_9: 2.37,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 3.79,
    avg_round_10: 2.71,
    app_flat_10: 0,
    char_11: 'Lucy',
    app_rate_11: 2.74,
    avg_round_11: 2.83,
    app_flat_11: 0,
    char_12: 'Caesar',
    app_rate_12: 2.32,
    avg_round_12: 2.67,
    app_flat_12: 0,
    char_13: 'billy-kid',
    app_rate_13: 2.11,
    avg_round_13: 2.9,
    app_flat_13: 0,
    char_14: 'Burnice',
    app_rate_14: 1.89,
    avg_round_14: 2.67,
    app_flat_14: 0,
    char_15: 'Nekomata',
    app_rate_15: 1.68,
    avg_round_15: 2.5,
    app_flat_15: 0,
    char_16: 'soldier-11',
    app_rate_16: 0.84,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Piper',
    app_rate_17: 0.63,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'Corin',
    app_rate_18: 0.63,
    avg_round_18: 2.67,
    app_flat_18: 0,
    char_19: 'Qingyi',
    app_rate_19: 0.63,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: 'Koleda',
    app_rate_20: 0.21,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'soldier-11',
    round: 2.77,
    char_1: 'Lucy',
    app_rate_1: 75.25,
    avg_round_1: 2.77,
    app_flat_1: 0,
    char_2: 'Caesar',
    app_rate_2: 32.34,
    avg_round_2: 2.8,
    app_flat_2: 0,
    char_3: 'Lighter',
    app_rate_3: 28.38,
    avg_round_3: 2.8,
    app_flat_3: 0,
    char_4: 'Koleda',
    app_rate_4: 16.83,
    avg_round_4: 2.75,
    app_flat_4: 0,
    char_5: 'Burnice',
    app_rate_5: 14.52,
    avg_round_5: 2.72,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 13.86,
    avg_round_6: 2.86,
    app_flat_6: 0,
    char_7: 'Ben',
    app_rate_7: 6.93,
    avg_round_7: 2.67,
    app_flat_7: 0,
    char_8: 'nicole-demara',
    app_rate_8: 2.31,
    avg_round_8: 2.83,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 1.32,
    avg_round_9: 3,
    app_flat_9: 0,
    char_10: 'Piper',
    app_rate_10: 1.32,
    avg_round_10: 2.25,
    app_flat_10: 0,
    char_11: 'jane-doe',
    app_rate_11: 1.32,
    avg_round_11: 2.67,
    app_flat_11: 0,
    char_12: 'Rina',
    app_rate_12: 0.99,
    avg_round_12: 3,
    app_flat_12: 0,
    char_13: 'Yanagi',
    app_rate_13: 0.99,
    avg_round_13: 2.67,
    app_flat_13: 0,
    char_14: 'Lycaon',
    app_rate_14: 0.66,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: 'Soukaku',
    app_rate_15: 0.66,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'Nekomata',
    app_rate_16: 0.33,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 0.33,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'Ellen',
    app_rate_18: 0.33,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Piper',
    round: 2.79,
    char_1: 'Lucy',
    app_rate_1: 53.28,
    avg_round_1: 2.83,
    app_flat_1: 0,
    char_2: 'Burnice',
    app_rate_2: 46.99,
    avg_round_2: 2.86,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 38.25,
    avg_round_3: 2.83,
    app_flat_3: 0,
    char_4: 'Yanagi',
    app_rate_4: 19.95,
    avg_round_4: 2.89,
    app_flat_4: 0,
    char_5: 'jane-doe',
    app_rate_5: 6.83,
    avg_round_5: 2.71,
    app_flat_5: 0,
    char_6: 'Nekomata',
    app_rate_6: 6.28,
    avg_round_6: 2.64,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 4.92,
    avg_round_7: 2.83,
    app_flat_7: 0,
    char_8: 'Seth',
    app_rate_8: 4.1,
    avg_round_8: 2.77,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 3.01,
    avg_round_9: 2.3,
    app_flat_9: 0,
    char_10: 'Corin',
    app_rate_10: 2.46,
    avg_round_10: 2.56,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 2.19,
    avg_round_11: 2.38,
    app_flat_11: 0,
    char_12: 'Qingyi',
    app_rate_12: 1.91,
    avg_round_12: 2.4,
    app_flat_12: 0,
    char_13: 'Lighter',
    app_rate_13: 1.64,
    avg_round_13: 2.67,
    app_flat_13: 0,
    char_14: 'Rina',
    app_rate_14: 1.37,
    avg_round_14: 2.8,
    app_flat_14: 0,
    char_15: 'Ben',
    app_rate_15: 1.09,
    avg_round_15: 2.5,
    app_flat_15: 0,
    char_16: 'Soukaku',
    app_rate_16: 1.09,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'soldier-11',
    app_rate_17: 1.09,
    avg_round_17: 2.25,
    app_flat_17: 0,
    char_18: 'anby-demara',
    app_rate_18: 0.82,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: 'Lycaon',
    app_rate_19: 0.82,
    avg_round_19: 2.33,
    app_flat_19: 0,
    char_20: 'Ellen',
    app_rate_20: 0.82,
    avg_round_20: 2,
    app_flat_20: 0,
    char_21: 'billy-kid',
    app_rate_21: 0.27,
    avg_round_21: 2,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Koleda',
    round: 2.8,
    char_1: 'Lucy',
    app_rate_1: 31.28,
    avg_round_1: 2.76,
    app_flat_1: 0,
    char_2: 'Ellen',
    app_rate_2: 24.17,
    avg_round_2: 2.88,
    app_flat_2: 0,
    char_3: 'soldier-11',
    app_rate_3: 24.17,
    avg_round_3: 2.75,
    app_flat_3: 0,
    char_4: 'nicole-demara',
    app_rate_4: 22.75,
    avg_round_4: 2.89,
    app_flat_4: 0,
    char_5: 'Soukaku',
    app_rate_5: 22.27,
    avg_round_5: 2.89,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 21.33,
    avg_round_6: 2.88,
    app_flat_6: 0,
    char_7: 'Burnice',
    app_rate_7: 10.43,
    avg_round_7: 2.68,
    app_flat_7: 0,
    char_8: 'Piper',
    app_rate_8: 8.53,
    avg_round_8: 2.83,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 6.64,
    avg_round_9: 2.77,
    app_flat_9: 0,
    char_10: 'Yanagi',
    app_rate_10: 5.21,
    avg_round_10: 2.89,
    app_flat_10: 0,
    char_11: 'Caesar',
    app_rate_11: 3.79,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Ben',
    app_rate_12: 3.79,
    avg_round_12: 2.5,
    app_flat_12: 0,
    char_13: 'Anton',
    app_rate_13: 3.32,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 3.32,
    avg_round_14: 2.67,
    app_flat_14: 0,
    char_15: 'Rina',
    app_rate_15: 2.84,
    avg_round_15: 2.6,
    app_flat_15: 0,
    char_16: 'Seth',
    app_rate_16: 2.37,
    avg_round_16: 2.6,
    app_flat_16: 0,
    char_17: 'Qingyi',
    app_rate_17: 0.95,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'Nekomata',
    app_rate_18: 0.95,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.47,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'Lycaon',
    app_rate_20: 0.47,
    avg_round_20: 1,
    app_flat_20: 0,
    char_21: 'anby-demara',
    app_rate_21: 0.47,
    avg_round_21: 3,
    app_flat_21: 0,
    char_22: 'Corin',
    app_rate_22: 0.47,
    avg_round_22: 3,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Soukaku',
    round: 2.81,
    char_1: 'Ellen',
    app_rate_1: 94.44,
    avg_round_1: 2.82,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 52.21,
    avg_round_2: 2.81,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 13.4,
    avg_round_3: 2.88,
    app_flat_3: 0,
    char_4: 'Qingyi',
    app_rate_4: 12.6,
    avg_round_4: 2.84,
    app_flat_4: 0,
    char_5: 'Lighter',
    app_rate_5: 7.84,
    avg_round_5: 2.86,
    app_flat_5: 0,
    char_6: 'anby-demara',
    app_rate_6: 3.89,
    avg_round_6: 2.64,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 3.15,
    avg_round_7: 2.89,
    app_flat_7: 0,
    char_8: 'Yanagi',
    app_rate_8: 2.48,
    avg_round_8: 2.75,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 2.48,
    avg_round_9: 2.55,
    app_flat_9: 0,
    char_10: 'Rina',
    app_rate_10: 1.88,
    avg_round_10: 2.7,
    app_flat_10: 0,
    char_11: 'Corin',
    app_rate_11: 0.87,
    avg_round_11: 2.38,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 0.67,
    avg_round_12: 2.78,
    app_flat_12: 0,
    char_13: 'Lucy',
    app_rate_13: 0.67,
    avg_round_13: 2.43,
    app_flat_13: 0,
    char_14: 'jane-doe',
    app_rate_14: 0.67,
    avg_round_14: 2.22,
    app_flat_14: 0,
    char_15: 'Seth',
    app_rate_15: 0.4,
    avg_round_15: 2.67,
    app_flat_15: 0,
    char_16: 'zhu-yuan',
    app_rate_16: 0.4,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'Burnice',
    app_rate_17: 0.34,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'Piper',
    app_rate_18: 0.27,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 0.2,
    avg_round_19: 2.67,
    app_flat_19: 0,
    char_20: 'Nekomata',
    app_rate_20: 0.13,
    avg_round_20: 2.5,
    app_flat_20: 0,
    char_21: 'soldier-11',
    app_rate_21: 0.13,
    avg_round_21: 2,
    app_flat_21: 0,
    char_22: 'Ben',
    app_rate_22: 0.07,
    avg_round_22: 2,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Ellen',
    round: 2.82,
    char_1: 'Soukaku',
    app_rate_1: 79.29,
    avg_round_1: 2.82,
    app_flat_1: 0,
    char_2: 'Lycaon',
    app_rate_2: 57.51,
    avg_round_2: 2.84,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 20.88,
    avg_round_3: 2.89,
    app_flat_3: 0,
    char_4: 'Lighter',
    app_rate_4: 12.61,
    avg_round_4: 2.88,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 11.48,
    avg_round_5: 2.83,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 4.95,
    avg_round_6: 2.68,
    app_flat_6: 0,
    char_7: 'anby-demara',
    app_rate_7: 3.04,
    avg_round_7: 2.71,
    app_flat_7: 0,
    char_8: 'Koleda',
    app_rate_8: 2.87,
    avg_round_8: 2.88,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 2.59,
    avg_round_9: 2.64,
    app_flat_9: 0,
    char_10: 'Lucy',
    app_rate_10: 1.01,
    avg_round_10: 2.77,
    app_flat_10: 0,
    char_11: 'zhu-yuan',
    app_rate_11: 0.56,
    avg_round_11: 2.17,
    app_flat_11: 0,
    char_12: 'grace-howard',
    app_rate_12: 0.39,
    avg_round_12: 2.71,
    app_flat_12: 0,
    char_13: 'jane-doe',
    app_rate_13: 0.39,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Burnice',
    app_rate_14: 0.34,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Yanagi',
    app_rate_15: 0.28,
    avg_round_15: 2.75,
    app_flat_15: 0,
    char_16: 'Piper',
    app_rate_16: 0.17,
    avg_round_16: 2,
    app_flat_16: 0,
    char_17: 'Ben',
    app_rate_17: 0.06,
    avg_round_17: 2,
    app_flat_17: 0,
    char_18: 'soldier-11',
    app_rate_18: 0.06,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: 'Corin',
    app_rate_19: 0.06,
    avg_round_19: 1,
    app_flat_19: 0,
    char_20: 'billy-kid',
    app_rate_20: 0.06,
    avg_round_20: 1,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Seth',
    round: 2.82,
    char_1: 'Yanagi',
    app_rate_1: 53.14,
    avg_round_1: 2.87,
    app_flat_1: 0,
    char_2: 'jane-doe',
    app_rate_2: 44.96,
    avg_round_2: 2.77,
    app_flat_2: 0,
    char_3: 'Rina',
    app_rate_3: 25.0,
    avg_round_3: 2.85,
    app_flat_3: 0,
    char_4: 'Burnice',
    app_rate_4: 21.77,
    avg_round_4: 2.85,
    app_flat_4: 0,
    char_5: 'Caesar',
    app_rate_5: 14.45,
    avg_round_5: 2.86,
    app_flat_5: 0,
    char_6: 'Lucy',
    app_rate_6: 13.78,
    avg_round_6: 2.81,
    app_flat_6: 0,
    char_7: 'grace-howard',
    app_rate_7: 6.65,
    avg_round_7: 2.73,
    app_flat_7: 0,
    char_8: 'Qingyi',
    app_rate_8: 5.8,
    avg_round_8: 2.79,
    app_flat_8: 0,
    char_9: 'anby-demara',
    app_rate_9: 3.8,
    avg_round_9: 2.64,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 3.71,
    avg_round_10: 2.93,
    app_flat_10: 0,
    char_11: 'Piper',
    app_rate_11: 1.43,
    avg_round_11: 2.77,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 0.67,
    avg_round_12: 2.71,
    app_flat_12: 0,
    char_13: 'Soukaku',
    app_rate_13: 0.57,
    avg_round_13: 2.67,
    app_flat_13: 0,
    char_14: 'Lighter',
    app_rate_14: 0.48,
    avg_round_14: 2,
    app_flat_14: 0,
    char_15: 'Koleda',
    app_rate_15: 0.48,
    avg_round_15: 2.6,
    app_flat_15: 0,
    char_16: 'Anton',
    app_rate_16: 0.38,
    avg_round_16: 2.67,
    app_flat_16: 0,
    char_17: '-',
    app_rate_17: 0.0,
    avg_round_17: 0.0,
    app_flat_17: 0,
    char_18: '-',
    app_rate_18: 0.0,
    avg_round_18: 0.0,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'jane-doe',
    round: 2.82,
    char_1: 'Seth',
    app_rate_1: 50.81,
    avg_round_1: 2.77,
    app_flat_1: 0,
    char_2: 'Burnice',
    app_rate_2: 42.86,
    avg_round_2: 2.9,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 31.58,
    avg_round_3: 2.88,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 30.4,
    avg_round_4: 2.87,
    app_flat_4: 0,
    char_5: 'Rina',
    app_rate_5: 8.92,
    avg_round_5: 2.69,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 6.02,
    avg_round_6: 2.77,
    app_flat_6: 0,
    char_7: 'Yanagi',
    app_rate_7: 5.91,
    avg_round_7: 2.71,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 4.08,
    avg_round_8: 2.72,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 3.54,
    avg_round_9: 2.71,
    app_flat_9: 0,
    char_10: 'Piper',
    app_rate_10: 2.69,
    avg_round_10: 2.71,
    app_flat_10: 0,
    char_11: 'anby-demara',
    app_rate_11: 1.93,
    avg_round_11: 2.71,
    app_flat_11: 0,
    char_12: 'Koleda',
    app_rate_12: 1.5,
    avg_round_12: 2.77,
    app_flat_12: 0,
    char_13: 'Lighter',
    app_rate_13: 1.29,
    avg_round_13: 2.29,
    app_flat_13: 0,
    char_14: 'Soukaku',
    app_rate_14: 1.07,
    avg_round_14: 2.22,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 0.75,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'zhu-yuan',
    app_rate_16: 0.43,
    avg_round_16: 2.75,
    app_flat_16: 0,
    char_17: 'soldier-11',
    app_rate_17: 0.43,
    avg_round_17: 2.67,
    app_flat_17: 0,
    char_18: 'Corin',
    app_rate_18: 0.11,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Nekomata',
    app_rate_19: 0.11,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'Anton',
    app_rate_20: 0.11,
    avg_round_20: 1,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.11,
    avg_round_21: 1,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lighter',
    round: 2.82,
    char_1: 'Ellen',
    app_rate_1: 55.58,
    avg_round_1: 2.88,
    app_flat_1: 0,
    char_2: 'Soukaku',
    app_rate_2: 29.03,
    avg_round_2: 2.86,
    app_flat_2: 0,
    char_3: 'Lucy',
    app_rate_3: 22.83,
    avg_round_3: 2.7,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 21.34,
    avg_round_4: 2.8,
    app_flat_4: 0,
    char_5: 'Lycaon',
    app_rate_5: 20.6,
    avg_round_5: 2.94,
    app_flat_5: 0,
    char_6: 'Burnice',
    app_rate_6: 16.13,
    avg_round_6: 2.85,
    app_flat_6: 0,
    char_7: 'Caesar',
    app_rate_7: 13.65,
    avg_round_7: 2.94,
    app_flat_7: 0,
    char_8: 'Rina',
    app_rate_8: 3.23,
    avg_round_8: 2.8,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 2.98,
    avg_round_9: 2.29,
    app_flat_9: 0,
    char_10: 'nicole-demara',
    app_rate_10: 2.73,
    avg_round_10: 2.75,
    app_flat_10: 0,
    char_11: 'Yanagi',
    app_rate_11: 2.73,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 2.48,
    avg_round_12: 2.83,
    app_flat_12: 0,
    char_13: 'Piper',
    app_rate_13: 1.49,
    avg_round_13: 2.67,
    app_flat_13: 0,
    char_14: 'grace-howard',
    app_rate_14: 1.24,
    avg_round_14: 2.4,
    app_flat_14: 0,
    char_15: 'Seth',
    app_rate_15: 1.24,
    avg_round_15: 2,
    app_flat_15: 0,
    char_16: 'Ben',
    app_rate_16: 0.99,
    avg_round_16: 2.75,
    app_flat_16: 0,
    char_17: 'Anton',
    app_rate_17: 0.74,
    avg_round_17: 2.33,
    app_flat_17: 0,
    char_18: 'Corin',
    app_rate_18: 0.25,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: 'Nekomata',
    app_rate_19: 0.25,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'billy-kid',
    app_rate_20: 0.25,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: 'Qingyi',
    app_rate_21: 0.25,
    avg_round_21: 1,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lycaon',
    round: 2.83,
    char_1: 'Ellen',
    app_rate_1: 92.91,
    avg_round_1: 2.84,
    app_flat_1: 0,
    char_2: 'Soukaku',
    app_rate_2: 70.82,
    avg_round_2: 2.81,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 13.45,
    avg_round_3: 2.95,
    app_flat_3: 0,
    char_4: 'Lighter',
    app_rate_4: 7.55,
    avg_round_4: 2.94,
    app_flat_4: 0,
    char_5: 'nicole-demara',
    app_rate_5: 5.0,
    avg_round_5: 2.84,
    app_flat_5: 0,
    char_6: 'zhu-yuan',
    app_rate_6: 3.0,
    avg_round_6: 2.81,
    app_flat_6: 0,
    char_7: 'Rina',
    app_rate_7: 2.55,
    avg_round_7: 2.81,
    app_flat_7: 0,
    char_8: 'Corin',
    app_rate_8: 1.27,
    avg_round_8: 2.71,
    app_flat_8: 0,
    char_9: 'Yanagi',
    app_rate_9: 0.91,
    avg_round_9: 2.86,
    app_flat_9: 0,
    char_10: 'Lucy',
    app_rate_10: 0.73,
    avg_round_10: 2.83,
    app_flat_10: 0,
    char_11: 'Qingyi',
    app_rate_11: 0.45,
    avg_round_11: 3,
    app_flat_11: 0,
    char_12: 'Piper',
    app_rate_12: 0.27,
    avg_round_12: 2.33,
    app_flat_12: 0,
    char_13: 'soldier-11',
    app_rate_13: 0.18,
    avg_round_13: 2,
    app_flat_13: 0,
    char_14: 'Burnice',
    app_rate_14: 0.18,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 0.09,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'Koleda',
    app_rate_16: 0.09,
    avg_round_16: 1,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.09,
    avg_round_17: 3,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.09,
    avg_round_18: 2,
    app_flat_18: 0,
    char_19: '-',
    app_rate_19: 0.0,
    avg_round_19: 0.0,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Lucy',
    round: 2.86,
    char_1: 'Burnice',
    app_rate_1: 51.12,
    avg_round_1: 2.91,
    app_flat_1: 0,
    char_2: 'Yanagi',
    app_rate_2: 31.79,
    avg_round_2: 2.95,
    app_flat_2: 0,
    char_3: 'jane-doe',
    app_rate_3: 23.48,
    avg_round_3: 2.88,
    app_flat_3: 0,
    char_4: 'soldier-11',
    app_rate_4: 18.21,
    avg_round_4: 2.77,
    app_flat_4: 0,
    char_5: 'Piper',
    app_rate_5: 15.58,
    avg_round_5: 2.83,
    app_flat_5: 0,
    char_6: 'Caesar',
    app_rate_6: 15.5,
    avg_round_6: 2.84,
    app_flat_6: 0,
    char_7: 'Seth',
    app_rate_7: 11.58,
    avg_round_7: 2.81,
    app_flat_7: 0,
    char_8: 'Lighter',
    app_rate_8: 7.35,
    avg_round_8: 2.7,
    app_flat_8: 0,
    char_9: 'Koleda',
    app_rate_9: 5.27,
    avg_round_9: 2.76,
    app_flat_9: 0,
    char_10: 'Qingyi',
    app_rate_10: 4.07,
    avg_round_10: 2.88,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 2.72,
    avg_round_11: 2.78,
    app_flat_11: 0,
    char_12: 'Nekomata',
    app_rate_12: 2.4,
    avg_round_12: 2.82,
    app_flat_12: 0,
    char_13: 'Corin',
    app_rate_13: 1.76,
    avg_round_13: 2.65,
    app_flat_13: 0,
    char_14: 'Ben',
    app_rate_14: 1.6,
    avg_round_14: 2.59,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 1.44,
    avg_round_15: 2.77,
    app_flat_15: 0,
    char_16: 'Rina',
    app_rate_16: 1.44,
    avg_round_16: 2.88,
    app_flat_16: 0,
    char_17: 'anby-demara',
    app_rate_17: 1.04,
    avg_round_17: 2.83,
    app_flat_17: 0,
    char_18: 'nicole-demara',
    app_rate_18: 0.88,
    avg_round_18: 2.9,
    app_flat_18: 0,
    char_19: 'Soukaku',
    app_rate_19: 0.8,
    avg_round_19: 2.43,
    app_flat_19: 0,
    char_20: 'Lycaon',
    app_rate_20: 0.64,
    avg_round_20: 2.83,
    app_flat_20: 0,
    char_21: 'billy-kid',
    app_rate_21: 0.56,
    avg_round_21: 3,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.4,
    avg_round_22: 3,
    app_flat_22: 0,
    char_23: 'Anton',
    app_rate_23: 0.32,
    avg_round_23: 2.5,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Rina',
    round: 2.87,
    char_1: 'Yanagi',
    app_rate_1: 69.06,
    avg_round_1: 2.93,
    app_flat_1: 0,
    char_2: 'Seth',
    app_rate_2: 27.77,
    avg_round_2: 2.85,
    app_flat_2: 0,
    char_3: 'Burnice',
    app_rate_3: 22.28,
    avg_round_3: 2.96,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 15.52,
    avg_round_4: 2.97,
    app_flat_4: 0,
    char_5: 'Qingyi',
    app_rate_5: 14.36,
    avg_round_5: 2.87,
    app_flat_5: 0,
    char_6: 'Ellen',
    app_rate_6: 9.29,
    avg_round_6: 2.68,
    app_flat_6: 0,
    char_7: 'jane-doe',
    app_rate_7: 8.76,
    avg_round_7: 2.69,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 5.91,
    avg_round_8: 2.75,
    app_flat_8: 0,
    char_9: 'Anton',
    app_rate_9: 5.6,
    avg_round_9: 2.77,
    app_flat_9: 0,
    char_10: 'anby-demara',
    app_rate_10: 4.75,
    avg_round_10: 2.67,
    app_flat_10: 0,
    char_11: 'Lycaon',
    app_rate_11: 2.96,
    avg_round_11: 2.81,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 2.96,
    avg_round_12: 2.7,
    app_flat_12: 0,
    char_13: 'Corin',
    app_rate_13: 2.53,
    avg_round_13: 2.94,
    app_flat_13: 0,
    char_14: 'Lucy',
    app_rate_14: 1.9,
    avg_round_14: 2.88,
    app_flat_14: 0,
    char_15: 'Lighter',
    app_rate_15: 1.37,
    avg_round_15: 2.8,
    app_flat_15: 0,
    char_16: 'nicole-demara',
    app_rate_16: 1.16,
    avg_round_16: 2.89,
    app_flat_16: 0,
    char_17: 'zhu-yuan',
    app_rate_17: 1.06,
    avg_round_17: 2.67,
    app_flat_17: 0,
    char_18: 'Koleda',
    app_rate_18: 0.63,
    avg_round_18: 2.6,
    app_flat_18: 0,
    char_19: 'Piper',
    app_rate_19: 0.53,
    avg_round_19: 2.8,
    app_flat_19: 0,
    char_20: 'soldier-11',
    app_rate_20: 0.32,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: 'Ben',
    app_rate_21: 0.11,
    avg_round_21: 3,
    app_flat_21: 0,
    char_22: 'Nekomata',
    app_rate_22: 0.11,
    avg_round_22: 1,
    app_flat_22: 0,
    char_23: 'billy-kid',
    app_rate_23: 0.11,
    avg_round_23: 1,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Caesar',
    round: 2.89,
    char_1: 'Yanagi',
    app_rate_1: 36.55,
    avg_round_1: 2.96,
    app_flat_1: 0,
    char_2: 'Burnice',
    app_rate_2: 35.98,
    avg_round_2: 2.93,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 21.19,
    avg_round_3: 2.89,
    app_flat_3: 0,
    char_4: 'jane-doe',
    app_rate_4: 16.16,
    avg_round_4: 2.87,
    app_flat_4: 0,
    char_5: 'Soukaku',
    app_rate_5: 11.42,
    avg_round_5: 2.88,
    app_flat_5: 0,
    char_6: 'Lucy',
    app_rate_6: 11.08,
    avg_round_6: 2.84,
    app_flat_6: 0,
    char_7: 'Seth',
    app_rate_7: 8.68,
    avg_round_7: 2.86,
    app_flat_7: 0,
    char_8: 'Lycaon',
    app_rate_8: 8.45,
    avg_round_8: 2.95,
    app_flat_8: 0,
    char_9: 'Rina',
    app_rate_9: 8.4,
    avg_round_9: 2.97,
    app_flat_9: 0,
    char_10: 'Piper',
    app_rate_10: 8.0,
    avg_round_10: 2.83,
    app_flat_10: 0,
    char_11: 'nicole-demara',
    app_rate_11: 7.25,
    avg_round_11: 2.86,
    app_flat_11: 0,
    char_12: 'zhu-yuan',
    app_rate_12: 6.8,
    avg_round_12: 2.89,
    app_flat_12: 0,
    char_13: 'soldier-11',
    app_rate_13: 5.6,
    avg_round_13: 2.8,
    app_flat_13: 0,
    char_14: 'Lighter',
    app_rate_14: 3.14,
    avg_round_14: 2.94,
    app_flat_14: 0,
    char_15: 'Qingyi',
    app_rate_15: 2.74,
    avg_round_15: 2.71,
    app_flat_15: 0,
    char_16: 'Nekomata',
    app_rate_16: 2.11,
    avg_round_16: 2.78,
    app_flat_16: 0,
    char_17: 'grace-howard',
    app_rate_17: 1.83,
    avg_round_17: 2.75,
    app_flat_17: 0,
    char_18: 'Corin',
    app_rate_18: 1.6,
    avg_round_18: 2.72,
    app_flat_18: 0,
    char_19: 'billy-kid',
    app_rate_19: 1.09,
    avg_round_19: 2.83,
    app_flat_19: 0,
    char_20: 'Anton',
    app_rate_20: 0.69,
    avg_round_20: 2.5,
    app_flat_20: 0,
    char_21: 'anby-demara',
    app_rate_21: 0.63,
    avg_round_21: 2.67,
    app_flat_21: 0,
    char_22: 'Koleda',
    app_rate_22: 0.46,
    avg_round_22: 3,
    app_flat_22: 0,
    char_23: 'Ben',
    app_rate_23: 0.06,
    avg_round_23: 3,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'nicole-demara',
    round: 2.9,
    char_1: 'zhu-yuan',
    app_rate_1: 85.79,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 66.08,
    avg_round_2: 2.94,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 15.28,
    avg_round_3: 2.86,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 6.78,
    avg_round_4: 2.86,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 3.31,
    avg_round_5: 2.89,
    app_flat_5: 0,
    char_6: 'Lycaon',
    app_rate_6: 2.94,
    avg_round_6: 2.84,
    app_flat_6: 0,
    char_7: 'Koleda',
    app_rate_7: 2.56,
    avg_round_7: 2.89,
    app_flat_7: 0,
    char_8: 'Ellen',
    app_rate_8: 2.46,
    avg_round_8: 2.64,
    app_flat_8: 0,
    char_9: 'Nekomata',
    app_rate_9: 2.46,
    avg_round_9: 2.77,
    app_flat_9: 0,
    char_10: 'Seth',
    app_rate_10: 2.08,
    avg_round_10: 2.93,
    app_flat_10: 0,
    char_11: 'Soukaku',
    app_rate_11: 1.98,
    avg_round_11: 2.55,
    app_flat_11: 0,
    char_12: 'jane-doe',
    app_rate_12: 1.76,
    avg_round_12: 2.71,
    app_flat_12: 0,
    char_13: 'billy-kid',
    app_rate_13: 1.39,
    avg_round_13: 2.78,
    app_flat_13: 0,
    char_14: 'Anton',
    app_rate_14: 0.96,
    avg_round_14: 2.94,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 0.64,
    avg_round_15: 2.82,
    app_flat_15: 0,
    char_16: 'Lucy',
    app_rate_16: 0.59,
    avg_round_16: 2.9,
    app_flat_16: 0,
    char_17: 'Lighter',
    app_rate_17: 0.59,
    avg_round_17: 2.75,
    app_flat_17: 0,
    char_18: 'Piper',
    app_rate_18: 0.59,
    avg_round_18: 2.3,
    app_flat_18: 0,
    char_19: 'Rina',
    app_rate_19: 0.59,
    avg_round_19: 2.89,
    app_flat_19: 0,
    char_20: 'Corin',
    app_rate_20: 0.43,
    avg_round_20: 2.71,
    app_flat_20: 0,
    char_21: 'soldier-11',
    app_rate_21: 0.37,
    avg_round_21: 2.83,
    app_flat_21: 0,
    char_22: 'Burnice',
    app_rate_22: 0.21,
    avg_round_22: 3,
    app_flat_22: 0,
    char_23: 'Ben',
    app_rate_23: 0.05,
    avg_round_23: 2,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Qingyi',
    round: 2.9,
    char_1: 'nicole-demara',
    app_rate_1: 68.53,
    avg_round_1: 2.94,
    app_flat_1: 0,
    char_2: 'zhu-yuan',
    app_rate_2: 66.54,
    avg_round_2: 2.94,
    app_flat_2: 0,
    char_3: 'Ellen',
    app_rate_3: 11.3,
    avg_round_3: 2.83,
    app_flat_3: 0,
    char_4: 'Soukaku',
    app_rate_4: 10.42,
    avg_round_4: 2.84,
    app_flat_4: 0,
    char_5: 'Yanagi',
    app_rate_5: 9.03,
    avg_round_5: 2.84,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 7.53,
    avg_round_6: 2.87,
    app_flat_6: 0,
    char_7: 'Anton',
    app_rate_7: 3.77,
    avg_round_7: 2.86,
    app_flat_7: 0,
    char_8: 'Seth',
    app_rate_8: 3.38,
    avg_round_8: 2.79,
    app_flat_8: 0,
    char_9: 'jane-doe',
    app_rate_9: 3.1,
    avg_round_9: 2.77,
    app_flat_9: 0,
    char_10: 'Lucy',
    app_rate_10: 2.83,
    avg_round_10: 2.88,
    app_flat_10: 0,
    char_11: 'Caesar',
    app_rate_11: 2.66,
    avg_round_11: 2.71,
    app_flat_11: 0,
    char_12: 'soldier-11',
    app_rate_12: 2.33,
    avg_round_12: 2.86,
    app_flat_12: 0,
    char_13: 'Burnice',
    app_rate_13: 2.05,
    avg_round_13: 2.71,
    app_flat_13: 0,
    char_14: 'Corin',
    app_rate_14: 1.77,
    avg_round_14: 2.77,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 1.55,
    avg_round_15: 2.78,
    app_flat_15: 0,
    char_16: 'Nekomata',
    app_rate_16: 1.44,
    avg_round_16: 2.63,
    app_flat_16: 0,
    char_17: 'billy-kid',
    app_rate_17: 0.55,
    avg_round_17: 2.71,
    app_flat_17: 0,
    char_18: 'Piper',
    app_rate_18: 0.39,
    avg_round_18: 2.4,
    app_flat_18: 0,
    char_19: 'Lycaon',
    app_rate_19: 0.28,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'anby-demara',
    app_rate_20: 0.17,
    avg_round_20: 2,
    app_flat_20: 0,
    char_21: 'Koleda',
    app_rate_21: 0.11,
    avg_round_21: 3,
    app_flat_21: 0,
    char_22: 'Ben',
    app_rate_22: 0.06,
    avg_round_22: 1,
    app_flat_22: 0,
    char_23: 'Lighter',
    app_rate_23: 0.06,
    avg_round_23: 1,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'zhu-yuan',
    round: 2.91,
    char_1: 'nicole-demara',
    app_rate_1: 96.75,
    avg_round_1: 2.92,
    app_flat_1: 0,
    char_2: 'Qingyi',
    app_rate_2: 72.35,
    avg_round_2: 2.94,
    app_flat_2: 0,
    char_3: 'anby-demara',
    app_rate_3: 14.58,
    avg_round_3: 2.88,
    app_flat_3: 0,
    char_4: 'Caesar',
    app_rate_4: 7.17,
    avg_round_4: 2.89,
    app_flat_4: 0,
    char_5: 'Koleda',
    app_rate_5: 2.71,
    avg_round_5: 2.88,
    app_flat_5: 0,
    char_6: 'Lycaon',
    app_rate_6: 1.99,
    avg_round_6: 2.81,
    app_flat_6: 0,
    char_7: 'Yanagi',
    app_rate_7: 0.84,
    avg_round_7: 2.89,
    app_flat_7: 0,
    char_8: 'Ellen',
    app_rate_8: 0.6,
    avg_round_8: 2.17,
    app_flat_8: 0,
    char_9: 'Lighter',
    app_rate_9: 0.6,
    avg_round_9: 2.83,
    app_flat_9: 0,
    char_10: 'Rina',
    app_rate_10: 0.6,
    avg_round_10: 2.67,
    app_flat_10: 0,
    char_11: 'Seth',
    app_rate_11: 0.42,
    avg_round_11: 2.71,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 0.36,
    avg_round_12: 2,
    app_flat_12: 0,
    char_13: 'Lucy',
    app_rate_13: 0.3,
    avg_round_13: 3,
    app_flat_13: 0,
    char_14: 'jane-doe',
    app_rate_14: 0.24,
    avg_round_14: 2.75,
    app_flat_14: 0,
    char_15: 'grace-howard',
    app_rate_15: 0.12,
    avg_round_15: 1,
    app_flat_15: 0,
    char_16: 'Burnice',
    app_rate_16: 0.06,
    avg_round_16: 1,
    app_flat_16: 0,
    char_17: 'Nekomata',
    app_rate_17: 0.06,
    avg_round_17: 1,
    app_flat_17: 0,
    char_18: 'billy-kid',
    app_rate_18: 0.06,
    avg_round_18: 1,
    app_flat_18: 0,
    char_19: 'Ben',
    app_rate_19: 0.06,
    avg_round_19: 2,
    app_flat_19: 0,
    char_20: '-',
    app_rate_20: 0.0,
    avg_round_20: 0.0,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Yanagi',
    round: 2.91,
    char_1: 'Burnice',
    app_rate_1: 52.63,
    avg_round_1: 2.94,
    app_flat_1: 0,
    char_2: 'Rina',
    app_rate_2: 33.13,
    avg_round_2: 2.93,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 32.42,
    avg_round_3: 2.96,
    app_flat_3: 0,
    char_4: 'Seth',
    app_rate_4: 28.32,
    avg_round_4: 2.87,
    app_flat_4: 0,
    char_5: 'Lucy',
    app_rate_5: 20.16,
    avg_round_5: 2.95,
    app_flat_5: 0,
    char_6: 'Qingyi',
    app_rate_6: 8.26,
    avg_round_6: 2.84,
    app_flat_6: 0,
    char_7: 'anby-demara',
    app_rate_7: 4.96,
    avg_round_7: 2.7,
    app_flat_7: 0,
    char_8: 'Piper',
    app_rate_8: 3.7,
    avg_round_8: 2.89,
    app_flat_8: 0,
    char_9: 'nicole-demara',
    app_rate_9: 3.14,
    avg_round_9: 2.89,
    app_flat_9: 0,
    char_10: 'jane-doe',
    app_rate_10: 2.79,
    avg_round_10: 2.71,
    app_flat_10: 0,
    char_11: 'grace-howard',
    app_rate_11: 2.68,
    avg_round_11: 2.72,
    app_flat_11: 0,
    char_12: 'Soukaku',
    app_rate_12: 1.87,
    avg_round_12: 2.75,
    app_flat_12: 0,
    char_13: 'zhu-yuan',
    app_rate_13: 0.71,
    avg_round_13: 2.89,
    app_flat_13: 0,
    char_14: 'Lighter',
    app_rate_14: 0.56,
    avg_round_14: 3,
    app_flat_14: 0,
    char_15: 'Koleda',
    app_rate_15: 0.56,
    avg_round_15: 2.89,
    app_flat_15: 0,
    char_16: 'Lycaon',
    app_rate_16: 0.51,
    avg_round_16: 2.86,
    app_flat_16: 0,
    char_17: 'Ellen',
    app_rate_17: 0.25,
    avg_round_17: 2.75,
    app_flat_17: 0,
    char_18: 'Ben',
    app_rate_18: 0.2,
    avg_round_18: 2.75,
    app_flat_18: 0,
    char_19: 'soldier-11',
    app_rate_19: 0.15,
    avg_round_19: 2.67,
    app_flat_19: 0,
    char_20: 'Anton',
    app_rate_20: 0.1,
    avg_round_20: 2,
    app_flat_20: 0,
    char_21: '-',
    app_rate_21: 0.0,
    avg_round_21: 0.0,
    app_flat_21: 0,
    char_22: '-',
    app_rate_22: 0.0,
    avg_round_22: 0.0,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  },
  {
    char: 'Burnice',
    round: 2.91,
    char_1: 'Yanagi',
    app_rate_1: 57.56,
    avg_round_1: 2.94,
    app_flat_1: 0,
    char_2: 'Lucy',
    app_rate_2: 35.46,
    avg_round_2: 2.91,
    app_flat_2: 0,
    char_3: 'Caesar',
    app_rate_3: 34.9,
    avg_round_3: 2.93,
    app_flat_3: 0,
    char_4: 'jane-doe',
    app_rate_4: 22.11,
    avg_round_4: 2.9,
    app_flat_4: 0,
    char_5: 'Seth',
    app_rate_5: 12.69,
    avg_round_5: 2.85,
    app_flat_5: 0,
    char_6: 'Rina',
    app_rate_6: 11.69,
    avg_round_6: 2.96,
    app_flat_6: 0,
    char_7: 'Piper',
    app_rate_7: 9.53,
    avg_round_7: 2.86,
    app_flat_7: 0,
    char_8: 'grace-howard',
    app_rate_8: 4.1,
    avg_round_8: 2.84,
    app_flat_8: 0,
    char_9: 'Lighter',
    app_rate_9: 3.6,
    avg_round_9: 2.85,
    app_flat_9: 0,
    char_10: 'soldier-11',
    app_rate_10: 2.44,
    avg_round_10: 2.72,
    app_flat_10: 0,
    char_11: 'Qingyi',
    app_rate_11: 2.05,
    avg_round_11: 2.71,
    app_flat_11: 0,
    char_12: 'Koleda',
    app_rate_12: 1.22,
    avg_round_12: 2.68,
    app_flat_12: 0,
    char_13: 'anby-demara',
    app_rate_13: 0.5,
    avg_round_13: 2.67,
    app_flat_13: 0,
    char_14: 'Nekomata',
    app_rate_14: 0.39,
    avg_round_14: 2.33,
    app_flat_14: 0,
    char_15: 'Ellen',
    app_rate_15: 0.33,
    avg_round_15: 3,
    app_flat_15: 0,
    char_16: 'Soukaku',
    app_rate_16: 0.28,
    avg_round_16: 3,
    app_flat_16: 0,
    char_17: 'Ben',
    app_rate_17: 0.22,
    avg_round_17: 2.67,
    app_flat_17: 0,
    char_18: 'nicole-demara',
    app_rate_18: 0.22,
    avg_round_18: 3,
    app_flat_18: 0,
    char_19: 'Corin',
    app_rate_19: 0.17,
    avg_round_19: 3,
    app_flat_19: 0,
    char_20: 'Lycaon',
    app_rate_20: 0.11,
    avg_round_20: 3,
    app_flat_20: 0,
    char_21: 'Anton',
    app_rate_21: 0.11,
    avg_round_21: 1.5,
    app_flat_21: 0,
    char_22: 'zhu-yuan',
    app_rate_22: 0.06,
    avg_round_22: 1,
    app_flat_22: 0,
    char_23: '-',
    app_rate_23: 0.0,
    avg_round_23: 0.0,
    app_flat_23: 0,
    char_24: '-',
    app_rate_24: 0.0,
    avg_round_24: 0.0,
    app_flat_24: 0,
    char_25: '-',
    app_rate_25: 0.0,
    avg_round_25: 0.0,
    app_flat_25: 0,
    char_26: '-',
    app_rate_26: 0.0,
    avg_round_26: 0.0,
    app_flat_26: 0,
    char_27: '-',
    app_rate_27: 0.0,
    avg_round_27: 0.0,
    app_flat_27: 0,
    char_28: '-',
    app_rate_28: 0.0,
    avg_round_28: 0.0,
    app_flat_28: 0,
    char_29: '-',
    app_rate_29: 0.0,
    avg_round_29: 0.0,
    app_flat_29: 0,
    char_30: '-',
    app_rate_30: 0.0,
    avg_round_30: 0.0,
    app_flat_30: 0
  }
];
